import { configureStore } from '@reduxjs/toolkit'
import creationProgressSlice from './slices/creationProgressSlice'
import walletFileSlice from './slices/walletFileSlice'
import ensNameTagSlice from './slices/ensNameTagSlice'
import selectedTokenSlice from './slices/selectedTokenSlice'
import recipentAddressSlice from './slices/recipentAddressSlice'
import sendNftDetailsSlice from './slices/sendNftDetailsSlice'

const store = configureStore({
  reducer: {
    walletFile: walletFileSlice.reducer,
    creationProgress: creationProgressSlice.reducer,
    ensNameTag: ensNameTagSlice.reducer,
    selectedToken: selectedTokenSlice.reducer,
    recipentAddress: recipentAddressSlice.reducer,
    sendNftDetails: sendNftDetailsSlice.reducer,
  },
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch