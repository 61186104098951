import { ConnectToAnchorWalletText } from "../FigmaComponets/ConnectToAnchorWalletText"
import { Footer } from "../FigmaComponets/Footer"
import Top from "../Componets/Top"
import { ConnectOrMakeWallet } from "../FigmaComponets/ConnectOrMakeWallet"
import { Button, Link, Snackbar } from "@mui/material"
import { useNavigate } from "react-router-dom"
import React from "react"
import LogEvent from "../functions/AnalyticsReporter"
import Constants from "../Constants"
import { ethers } from "ethers"

export default function InitialPage() {

    const hasLoaded = React.useRef<boolean>(false)

    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("")

    React.useEffect(() => {
        if (hasLoaded.current) {
            return
        }
        LogEvent("Visited Site")
        hasLoaded.current = true
    }, [])

    const navigate = useNavigate()

    return (
        <div className="InitialPage">
            <div>
                <div className="top-promotion">
                    <div className="unstyled-link">
                        <a href="https://github.com/Pauli-Group/wallet_cli" target="_blank" rel="noopener noreferrer">
                            Don't want to use Metamask? Click here to use the Command Line Interface!
                        </a>
                    </div>
                </div>
            </div>
            <br />
            <Top />
            <br />
            <ConnectToAnchorWalletText />
            <br />

            <div className="elevated-area" >
                <div className="elevated-area-content">
                    <ConnectOrMakeWallet setShowSnackbar={setShowSnackbar} setSnackbarMessage={setSnackbarMessage} />
                </div>
            </div>
            <br />

            <div className="collection">
                <div className="collection-item">
                    <Link
                        href="https://github.com/Pauli-Group/wallet_cli"
                        underline="none"
                        target="_blank"
                        rel="noopener noreferrer" >
                        <b>
                            USE THE COMMAND LINE INTERFACE (ADVANCED)
                        </b>
                    </Link>
                </div>

                <div className="vertical-separator" />

                <div className="collection-item">
                    <Button onClick={() => navigate('/faq')}>
                        <b>
                            FAQ
                        </b>
                    </Button>
                </div>


                <div className="vertical-separator" />

                <div className="collection-item">
                    <Link
                        href={Constants.discord_invite}
                        underline="none"
                        target="_blank"
                        rel="noopener noreferrer" >
                        <b>
                            JOIN OUR DISCORD
                        </b>
                    </Link>
                </div>
            </div>

            {/* <button onClick={() => {
            const { pri, pub, secret } = experimental_mk_key_pair()
            console.log(`pri --> `, pri)
            console.log(`pub --> `, pub)
            console.log(`secret --> `, secret)
        }}>
            run
        </button> */}

            <br />
            <br />
            <br />
            {/* <div className="the-very-bottom"> */}
            <Footer />
            {/* </div> */}

            <Snackbar
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={() => setShowSnackbar(false)}
                message={snackbarMessage}
            />
        </div>
    )
}


