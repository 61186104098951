import metamaskImage from "../assets/metamask.png";

export const ConnectToMetamaskButton = () => {
  return <div className="metamask-connect">
      <img className="metamask-image" src={metamaskImage} alt="the metamask logo a cartoon fox" />
      <div className="connect-to-text">Connect to</div>
      <div className="connect-to-mm-btn-flexcolumn">
        <div className="metamask-btn-background" />
      </div>
    </div>
};
