import React from 'react';
import { ChainData } from '../../FigmaComponets/ConnectOrMakeWallet';
import { TopLine } from '../../FigmaComponets/TopLine';
import useLamportWalletManager from '../../hooks/useLamportWalletManager';
import BaseOverlay from './BaseOverlay';
import InfoIcon from '@mui/icons-material/Info';
import { Avatar, Chip, IconButton, Tooltip } from '@mui/material';
import SendIcon from "@mui/icons-material/Send";
import { Button, } from "@mui/material"
import { ActivityIconStyle } from '../../pages/Home';
import { useAppDispatch } from '../../redux/hooks';
import { setActiveStep, setSteps } from '../../redux/slices/creationProgressSlice';
import { AppDispatch } from '../../redux/store';
import { setSelectedToken } from '../../redux/slices/selectedTokenSlice';
import { setNftDetails } from '../../redux/slices/sendNftDetailsSlice';
import { useSelector } from 'react-redux';
import { sendNftOverlaySteps } from './SendNftOverlay';
import { ethers } from 'ethers';
import MetamaskHelper from '../../MetamaskHelper';
import { supportedBlockchains } from 'lamportwalletmanager/src';
import AddressInput from '../AddressInput';
import EnsNameTag from '../EnsNameTag';

type NftCollectionOverlayProps = {
    collectionAddress: string,
    setShowOverlay: React.Dispatch<React.SetStateAction<boolean>>,
    showOverlay: boolean,
    chain: ChainData,
    setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>,
    setShowSnackbar: React.Dispatch<React.SetStateAction<boolean>>,

    setShowSendInterface: React.Dispatch<React.SetStateAction<boolean>>,
    showSendInterface: boolean,

    setTransactionInFlight: any,
    transactionInFlight: boolean,
}

type BodyProps = {
    collectionName: string,
}

export default function NftCollectionOverlay(props: NftCollectionOverlayProps) {
    const [contractName, setContractName] = React.useState<string>('')
    const [contractSymbol, setContractSymbol] = React.useState<string>('')
    const [amountOwned, setAmountOwned] = React.useState<number>(0)
    const [totalSupply, setTotalSupply] = React.useState<number | null>(null)
    const [heldTokens, setHeldTokens] = React.useState<string[]>([])
    const [tokenIdsToURI, setTokenIdsToURI] = React.useState<{ [key: string]: string }>({})

    const [loading, setLoading] = React.useState<boolean>(true)
    const [isErrorState, setIsErrorState] = React.useState<boolean>(false) // cannot find nessesary data
    const [errorMessage, setErrorMessage] = React.useState<string>('')

    const [isEnumerable, setIsEnumerable] = React.useState<boolean>(false)
    const [tokenToSend, setTokenToSend] = React.useState<string>('')
    const [ownerOfInputTokenId, setOwnerOfInputTokenId] = React.useState<string>('')
    // const [recipientAddress, setRecipientAddress] = React.useState<string>('')

    const lwm = useLamportWalletManager()

    const dispatch: AppDispatch = useAppDispatch()
    const isSendMode = useSelector((state: any) => state.sendNftDetails.isSendMode)

    const handleTokenIdChange = React.useCallback((e: any) => {
        setTokenToSend(e.target.value);
    }, [setTokenToSend]);


    // when sendMode changes --> console.log
    React.useEffect(() => {
        const loadOwner = async () => {
            if (lwm === null)
                return

            const _chain: ChainData | undefined = supportedBlockchains.find((chain) => chain.chainid === lwm.state.chainId)
            if (_chain === undefined)
                return
            const provider = await MetamaskHelper.connect(_chain)
            const contract = new ethers.Contract(props.collectionAddress, ['function ownerOf(uint256 _tokenId) external view returns (address)'], provider)
            const owner = await contract.ownerOf(tokenToSend)
            console.log(`owner: ${owner}`)
            setOwnerOfInputTokenId(owner)

            // setTokenToSend(owner)
        }
        loadOwner()

    }, [lwm, props.collectionAddress, tokenToSend])

    /**
     * @name handleNotErc721
     * @description Handle the case where the contract is not an ERC721 contract
     * @author William Doyle
     * @date January 30th 2023
     */
    const handleNotErc721 = React.useCallback(() => {
        props.setSnackbarMessage('This is not an ERC721 contract')
        props.setShowSnackbar(true)

        setErrorMessage('Contract Does Not Signal Support For The ERC721 Interface')
        setIsErrorState(true)
        setLoading(false)
        setTotalSupply(null)
    }, [props])

    /**
     * @name loadContractDetails
     * @description Load the contract details relevent to the NFT collection
     * @author William Doyle
     * @date January 30th 2023
     */
    const loadContractDetails = React.useCallback(async (): Promise<void> => {
        if (lwm === null)
            return

        const nftContract = lwm.getErc721Contract(props.collectionAddress)

        // CHECK FOR SUPPORTED INTERFACES
        const isErc721 = await nftContract.supportsInterface('0x80ac58cd')
            .then((res: any) => {
                if (res === true) 
                    return true
                console.log(`Contract does not indicate support for ERC721 via the standard InterfaceID (0x80ac58cd). If this is an ERC721 contract that predates the finalization of the ERC721 standard, try the interface ID 0x9a20483d instead. (this is the expected case with CryptoKitties)`)
                // maybe its a really old ERC721 from before the standard was finalised in which case try id 0x9a20483d which is the interfaceID used by CryptoKitties
                const isOldErc721 = nftContract.supportsInterface('0x9a20483d')
                return isOldErc721 
                // return res
            })
            .catch((err: any) => false)

        if ((typeof isErc721 !== "boolean") || (!isErc721)) {
            return handleNotErc721()
        }

        const isMetadataExtension = await nftContract.supportsInterface('0x5b5e139f') // ERC721Metadata [name, symbol, tokenURI]
        const isEnumerableExtension = await nftContract.supportsInterface('0x780e9d63') // ERC721Enumerable [totalSupply, tokenByIndex, tokenOfOwnerByIndex]

        console.log(`isMetadataExtension: ${isMetadataExtension}`)
        console.log(`isEnumerableExtension: ${isEnumerableExtension}`)

        setIsEnumerable(isEnumerableExtension)

        // GATHER THE DETAILS
        const balance = await nftContract.balanceOf(lwm.address)
        setAmountOwned(balance.toNumber())

        if (isMetadataExtension) {
            const name = await nftContract.name()
            const symbol = await nftContract.symbol()

            setContractName(name)
            setContractSymbol(symbol)
        }

        if (isEnumerableExtension) {
            const _totalSupply = await nftContract.totalSupply()
            setTotalSupply(_totalSupply.toNumber())

            const _heldTokens = await Promise.all(Array.from({ length: balance.toNumber() })
                .map((_, index: number) => nftContract.tokenOfOwnerByIndex(lwm.address, index)))

            const uris = await Promise.all(_heldTokens.map((token) => nftContract.tokenURI(token)))
            // / provider
            // const metadatas = await Promise.all(uris.map(async (uri) => await fetch(uri).then((res) => res.json())))

            setHeldTokens(_heldTokens.map((token) => token.toString()))

            for (let i = 0; i < _heldTokens.length; i++) {
                setTokenIdsToURI((prev) => {
                    return {
                        ...prev,
                        [_heldTokens[i].toString()]: uris[i]
                    }
                })
            }
        }


        // SET THE LOADING STATE
        setLoading(false)
    }, [lwm, props.collectionAddress, handleNotErc721, setHeldTokens])


    /**
     * useEffect to load the contract details
     */
    React.useEffect(() => {
        if (props.showOverlay)
            loadContractDetails()

        return () => {  // restore to default state upon exit
            setContractName('')
            setContractSymbol('')
            setAmountOwned(0)
            setTotalSupply(null)
            setHeldTokens([])
            setTokenIdsToURI({})
            setLoading(true)
        }
    }, [dispatch, loadContractDetails, props.showOverlay])




    const Body = (bodyProps: BodyProps) => {
        /**
         * @name handleSwitchToSend
         * @description close this overlay and open the send overlay
         * @author William Doyle
         * @date February 1st 2021
         */
        function handleSwitchToSend(token: string) {

            ////////////////////////////////////////////////////////////
            const details = {
                collectionName: bodyProps.collectionName,
                collectionAddress: props.collectionAddress,
                chain: props.chain,
            }

            dispatch(setNftDetails(details))

            ////////////////////////////////////////////////////////////

            dispatch(setSelectedToken(token))

            dispatch(setSteps(sendNftOverlaySteps))
            dispatch(setActiveStep(0))
            props.setShowSendInterface(true)
            props.setShowOverlay(false)
        }
        if (loading) // STILL QUERYING FOR COLLECTION DETAILS
            return <div>Loading...</div>

        if (isErrorState) // UNABLE TO LOAD THE COLLECTION DETAILS
            return <div>{errorMessage}</div>

        return <div>
            <h3>{bodyProps.collectionName}</h3>
            {
                // (() => {
                // if (loading) // STILL QUERYING FOR COLLECTION DETAILS
                //     return <div>Loading...</div>

                // if (isErrorState) // UNABLE TO LOAD THE COLLECTION DETAILS
                //     return <div>{errorMessage}</div>

                // return <div>
                <div>
                    <div className="collection-details">
                        <div className="collection-detail">
                            <div className="collection-detail-label">Address</div>
                            <div className="collection-detail-value">{props.collectionAddress}</div>
                        </div>
                        <div className="collection-detail">
                            <div className="collection-detail-label">Name</div>
                            <div className="collection-detail-value">{contractName}</div>
                        </div>
                        <div className="collection-detail">
                            <div className="collection-detail-label">Symbol</div>
                            <div className="collection-detail-value">{contractSymbol}</div>
                        </div>
                        <div className="collection-detail">
                            <div className="collection-detail-label">Total Supply</div>
                            <div className="collection-detail-value">{totalSupply ?? "NA"}</div>
                        </div>
                        <div className="collection-detail">
                            <div className="collection-detail-label">Secured By This Wallet</div>
                            <div className="collection-detail-value">{amountOwned}</div>
                        </div>
                    </div>
                    <br />
                    <TopLine />
                    <h3>
                        In Your Wallet
                    </h3>
                    {
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        (!isEnumerable) && <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                            id="not-enumerable"
                        >
                            <Chip
                                icon={<InfoIcon />}
                                label="This Contract Does Not Support Enumeration, Anchor Wallet Cannot Display Your Tokens At This Time."
                            />
                            <br />
                            <TopLine />
                            <h3>
                                Send A Token
                            </h3>
                            <p>
                                Enter the token ID of the token you would like to send. Because this contract does not support enumeration, <br />you will need to know the token ID of the token you would like to send.
                            </p>
                            <input
                                id="token-input"
                                key="token-id-user-input"
                                type="text"
                                placeholder="Token ID"
                                value={tokenToSend}
                                onChange={handleTokenIdChange}
                            />
                            <br />
                            input: {
                                tokenToSend
                            }
                            <br />
                            <div style={{
                                marginBottom: '10px'
                            }}>
                                <p>
                                    owner: {
                                        ownerOfInputTokenId
                                    }
                                </p>
                            </div>
                            <br />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSwitchToSend(tokenToSend)}
                                disabled={ownerOfInputTokenId !== lwm?.address}
                                style={{
                                    marginTop: '10px'
                                }}
                            >
                                Continue To Send
                            </Button>


                        </div>
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                    }
                    {
                        (() => {
                            if (!isEnumerable)
                                return null

                            if (heldTokens.length === 0)
                                return <div>
                                    <Chip
                                        icon={<InfoIcon />}
                                        label="You Do Not Own Any Tokens From This Collection."
                                    />
                                </div>

                            return <div className="scroll-area">
                                {
                                    (() => {
                                        return heldTokens.map((token, index) => {
                                            return <div className="token-details" key={index} style={{
                                                backgroundColor: index % 2 === 1 ? '#f5f5f5' : '#ffffff'
                                            }}>
                                                <div className="token-detail">
                                                    <div className="token-detail-label">Token ID</div>
                                                    <div className="token-detail-value">{token}</div>
                                                </div>
                                                <br />
                                                <div className="token-detail">
                                                    <div className="token-detail-label">Token URI</div>
                                                    <div className="token-detail-value">
                                                        <div className="unstyled-link">
                                                            <a href={tokenIdsToURI[token]} target="_blank" rel="noreferrer">{tokenIdsToURI[token]}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="token-detail">
                                                    <IconButton color='primary' onClick={() => {
                                                        handleSwitchToSend(token)
                                                    }} >
                                                        <Avatar style={ActivityIconStyle}>
                                                            <Tooltip title={`Send ${contractName} ${token}`}>
                                                                <SendIcon />
                                                            </Tooltip>
                                                        </Avatar>
                                                    </IconButton>
                                                </div>
                                                <TopLine />
                                            </div>
                                        })
                                    })()
                                }
                            </div>
                        })()
                    }
                </div>
                // })()
            }
        </div>
    }

    // const AccessBody = () => <Body collectionName={contractName} />

    return <BaseOverlay
        setShowOverlay={props.setShowOverlay}
        showOverlay={props.showOverlay}
        // Body={AccessBody}
        Body={() => <Body collectionName={contractName} />}
    >
    </BaseOverlay>
}