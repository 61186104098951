import { Snackbar } from "@mui/material";
import React, { ComponentType, ReactElement, ReactNode } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";

export type OverlayParams = {
    setShowOverlay: any,
    showOverlay: boolean,
    children: ReactNode,
    Body: ComponentType<any>,
}

/**
 * @name BaseOverlay
 * @description Base Overlay Component. This component is used to create other overlays.
 * @author William Doyle
 * @date January 29th 2023
 *  */
export default function BaseOverlay(props: OverlayParams) {
    const wrapperRef = React.useRef<HTMLDivElement | null>(null)
    useOnClickOutside(wrapperRef, () => props.setShowOverlay(false))

    if (props.showOverlay === false)
        return null

    return <div className='shadow'>
        <div ref={wrapperRef} className="overlay">
            <div className="overlay-content">
                <props.Body />
                {props.children}
            </div>
        </div>
    </div>
}
