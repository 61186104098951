/**
 *  @name downloadToFile
 *  @description Download a file to the user's computer 
 */
export default function downloadToFile(content: any, filename: string, contentType: string) {
    const a = document.createElement('a');
    const file = new Blob([content], { type: contentType });

    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();

    URL.revokeObjectURL(a.href);
}