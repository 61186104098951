import React from "react"
import { ChainData } from "../../FigmaComponets/ConnectOrMakeWallet"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import useTxHistory, { TxReceiptAndBlock } from "../../hooks/useTxHistory"

type ActivityOverlayParams = {
    setShowOverlay: any,
    showOverlay: boolean,
    chain: ChainData,
}

/**
 * @name ActivityOverlay
 * @description Show the past transactions of the user
 * @author William Doyle
 * @date November 24th 2022
 */
export default function ActivityOverlay(props: ActivityOverlayParams) {
    const wrapperRef = React.useRef<HTMLDivElement | null>(null)
    useOnClickOutside(wrapperRef, () => props.setShowOverlay(false))

    const history = useTxHistory()

    if (props.showOverlay === false)
        return null

    return <div className='shadow'>
        <div ref={wrapperRef} className="overlay">
            <div className="overlay-content">
                <h3>Activity</h3>
                <h4>Here you can see your past transactions</h4>
                <br />
                <div className="activity-list" >
                    <table border={1}>
                        <thead>
                            <tr>
                                <th>Transaction Hash</th>
                                <th>Block Number</th>
                                <th>Block Timestamp</th>
                                <th>Gas Used</th>
                                <th>From (Gas Payer)</th>
                                <th>Status</th>
                                <th>Confirmations</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                history.map((tr: TxReceiptAndBlock, index : number) => {
                                    if (tr.receipt === null)
                                        return null
                                    return <tr key={index}>
                                        <td>
                                            <a href={`${props.chain.explorer}tx/${tr.receipt.transactionHash}`} target="_blank" rel="noreferrer">
                                                {tr.receipt.transactionHash}
                                            </a>
                                        </td>
                                        <td>
                                            <a href={`${props.chain.explorer}block/${tr.receipt.blockNumber}`} target="_blank" rel="noreferrer">
                                                {tr.receipt.blockNumber}
                                            </a>
                                        </td>
                                        <td>{new Date((tr?.block?.timestamp ?? 0) * 1000).toLocaleString()}</td>
                                        <td>{tr.receipt.gasUsed.toString()}</td>
                                        <td>
                                            <a href={`${props.chain.explorer}address/${tr.receipt.from}`} target="_blank" rel="noreferrer">
                                                {tr.receipt.from}
                                            </a>
                                        </td>
                                        <td>{(() => {
                                            if (tr.receipt.status === 1)
                                                return 'Success'
                                            return 'Failure'
                                        })()}</td>
                                        <td>{tr.receipt.confirmations}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}