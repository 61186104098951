import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import WebFont from "webfontloader";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import NotFound from './pages/404';
import InitialPage from './pages/InitialPage';
import { Provider } from 'react-redux'
import store from './redux/store'
import HomePage from './pages/Home';
import ListenerExperiment from './pages/ListenerExperiment';
import AdminPage from './pages/AdminPage';
import ErrorPage from './pages/Error';
import FAQ from './pages/FAQ';
import Playground from './pages/Playground';
import Authentication from './pages/Authentication';
import ReleventAddresses from './pages/ReleventAddresses';
import Promotion from './pages/Promotion';
import VerifyMessagePage from './pages/VerifyMessagePage';
import Pull from './pages/Pull';
import TestnetAssets from './pages/TestnetAssets';

WebFont.load({
  google: {
    families: ["Poppins", "Montserrat:300,700"],
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        {/* <App /> */}
        <Routes>
          <Route path="/" element={<Layout />} >
            {/* <Route index element={<App />} /> */}
            <Route index element={<InitialPage/>} />
            <Route path="/initialpage" element={<InitialPage />} />
            <Route path="home" element={<HomePage />} />
            <Route path="listen" element={<ListenerExperiment/>} />
            <Route path="admin" element={<AdminPage />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="error" element={<ErrorPage />} />
            <Route path="playground" element={<Playground/>} />
            <Route path="authentication" element={<Authentication />} />
            <Route path="ReleventAddresses" element={<ReleventAddresses />} />
            <Route path="promo" element={<Promotion/>} />
            <Route path="verify" element={<VerifyMessagePage/>} />
            <Route path="pull" element={<Pull/>} />
            <Route path="testnetassets" element={<TestnetAssets/>} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
