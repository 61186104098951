import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useLamportWalletManager from '../hooks/useLamportWalletManager';
import { useAppDispatch } from "../redux/hooks";
import { AppDispatch } from "../redux/store";
import { setWalletFile } from "../redux/slices/walletFileSlice";
import { useNavigate } from "react-router-dom";
import { Tooltip } from '@mui/material';


export default function DisconnectButton() {
    const lwm = useLamportWalletManager()
    const dispactch: AppDispatch = useAppDispatch()
    const navigate = useNavigate()

    /**
     * @name disconnect
     * @description Disconnects the wallet from the app.. clears redux state and navigates to initial page
     * @author William Doyle
     * @date November 22nd 2022
     */
    async function disconnect() {
        dispactch(setWalletFile(''))
        navigate('/')
    }

    if (lwm === null)
        return <> </>

    return <div className="DisconnectButton" onClick={disconnect}>
        <Tooltip title="Disconnect Wallet">
            <ExitToAppIcon fontSize='large' />
        </Tooltip>
    </div>
}