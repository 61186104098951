import pauligrouplogo from "../assets/pauligrouplogo.png";
import { TopLine } from "./TopLine";

export const Footer = () => {
  return (
    <div
      className="column"
      // style={{ backgroundColor: "#ff0000" } }
    >
      <div
        className="column-spacer"
        // style={{ backgroundColor: "#0000ff" }}
      />
      <div
        className="bottom"
        // style={{ backgroundColor: "#00ff00" }}
      >
        <TopLine />
        <br />
        <div className="bottom-row">
          <div className="footer-element">
            <a href="https://pauli.group" target="_blank" rel="noopener noreferrer">
              <img style={{
                width: "27px",
                height: "24px",
              }} src={pauligrouplogo} alt={`The pauli Group Logo, a black gradiant with a diamond surrounded with other polygons`} />
            </a>

          </div>
          <div className="footer-element">
            © {new Date().getFullYear()} Pauli Group. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
};
