import React from "react";
import useLamportWalletManager from "./useLamportWalletManager";
import useLamportWalletUpdates, { WalletMutationEvent } from "./useLamportWalletUpdates";

/**
 * @name useLwmEthBalance
 * @description custom hook to get ETH balance from the LamportWalletManager
 * @author William Doyle
 * @date November 21st 2022 
 */
export default function useLwmEthBalance(): string | null {
  const lwm = useLamportWalletManager()
  const [ethBalance, setEthBalance] = React.useState<string | null>(null)
  const walletMutationEvents: WalletMutationEvent[] = useLamportWalletUpdates()

  React.useEffect(() => {
    (async () => {
      if (lwm === null) {
        setEthBalance(null)
        return
      }
      const ethBalance = await lwm.ethBalance()
      setEthBalance(ethBalance)

    })()
  }, [lwm, walletMutationEvents])

  return ethBalance
}
