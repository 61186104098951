
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import Linkify from 'react-linkify';
import Constants from "../Constants";

type QuestionAndAnswer = {
    question: string
    answer: string
}

const questionsAndAnswers: QuestionAndAnswer[] = [
    {
        question: 'Explain why Anchor Wallet is special.',
        // answer: 'Anchor Wallet is the first and only smart contract wallet deployed on major blockchains which is fully resistant from quantum computing technologies. Whether the blockchain upgrades or not or whether several forks appear with different signature methods, you can always prove ownership of your assets at any point in the future by signing with your quantum resistant keys. It is safe, cheap and extremely secure. Protect and backup your keys!'
        answer: `Anchor Wallets are the first and only long term solution to securing digital assets on EVM blockchains. While other wallets are protected with ECDSA cryptography which is vulrablue to emerging quantum computing technoligies, Anchor Wallets are protected with a quantum resistant signature scheme. This means that your assets are safe from quantum attacks.`

    },
    {
        question: 'Is my key uploaded to Metamask? Why do I need Metamask?',
        answer: 'Your keys are not uploaded anywhere, the cryptographic signatures are done locally on your computer and the keys are saved directly on your harddrive. An externally owned account (EOA) is required to create the wallet (in a process similar to minting an NFT) and to supply tranaction fees for the network. We currently support Metamask as an EOA and plan to provide more options in the near future.'
    },
    {
        question: 'What is the integrety check?',
        answer: 'The green check means that your key file is up to date and ready to be used with the current state of the blockchain.'
    },
    {
        question: 'Where can I read the source code of the contracts?',
        answer: 'Right here etherscan.io/address/0x5d58355aefa81aad5672dd39d6d8c73fdba237a9#code'
    },
    {
        question: 'Do you have a discord server?',
        answer: `Yep! Please drop in and say hello! ${Constants.discord_invite}`
    },
]


export default function FAQ() {
    const navigate = useNavigate()

    return <div className="faq-page" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }}>
        <div style={{ width: '50%' }}>
            <h1>FAQ</h1>
            <Linkify >
                {
                    questionsAndAnswers.map(({ question, answer }, i) => <div key={i}>
                        <h2>{question}</h2>
                        <p>{answer}</p>
                    </div>)
                }
            </Linkify>
            {
                Array.from({ length: 3 }).map((_, i) => <br key={i} />)
            }
            <Button variant="contained" onClick={() => navigate('/')}>
                back to home
            </Button>
        </div>
    </div>
}