import React from "react";
import { ethers } from 'ethers'
import { useAppDispatch } from "../redux/hooks";
import { AppDispatch } from "../redux/store";
import { useNavigate } from "react-router-dom";
import LamportWalletManager from 'lamportwalletmanager/src/LamportWalletManager'
import { setWalletFile } from "../redux/slices/walletFileSlice";
import supportedBlockchains from "lamportwalletmanager/src/supportedBlockchains.json";
import PlusImage from '../assets/Plus1.svg'
import FileUploader from "../Componets/FileUploader";
import LogEvent from "../functions/AnalyticsReporter";
import { NewWalletOverlay } from "../Componets/Overlay/NewWalletOverlay";
import { Tooltip } from "@mui/material";
import { setActiveStep } from "../redux/slices/creationProgressSlice";
import downloadToFile from "../functions/downloadToFile";

export type ChainData = {
    name: string,
    factoryAddress: string,
    rpc: string,
    chainid: string,
    price: string,
    explorer: string,
    isTestnet: boolean,
    currencyTicker: string,
}

type ConnectOrMakeWalletProps = {
    setSnackbarMessage: any,
    setShowSnackbar: any,
}

export const ConnectOrMakeWallet = (props: ConnectOrMakeWalletProps) => {
    const dispatch: AppDispatch = useAppDispatch()
    const navigate = useNavigate()

    const [showNewWalletOverlay, setShowNewWalletOverlay] = React.useState<boolean>(false)
    const [chain, setChain] = React.useState<ChainData>(supportedBlockchains[0])
    const [transactionInFlight, setTransactionInFlight] = React.useState<boolean>(false)
    const [signer, setSigner] = React.useState<ethers.providers.JsonRpcSigner | null>(null)

    /**
     * @name handleCreateNewWallet
     * @description create a new wallet and save the file
     * @author William Doyle
     * @date November 23rd 2022
     */
    async function handleCreateNewWallet() {
        if (signer === null) {
            throw new Error('Signer is null');
        }

        setTransactionInFlight(true)
        dispatch(setActiveStep(3))
        try {
            const lwm: LamportWalletManager = await LamportWalletManager.buyNew_mm(signer, chain.name, {
                gasLimit: null, //ethers.BigNumber.from('1000000'),
                gasPrice: null, // ethers.BigNumber.from('200000000000'),
                type: "UNSPECIFIED"//"MANUAL"
            })
            downloadToFile(lwm.toJSON(), `ANCHOR_${chain.name}_${lwm.state.walletAddress}.json`, 'application/json')

            // save lwm to redux
            dispatch(setWalletFile(lwm.toJSON()))
        } catch (e : any) {
            console.log( e.message)
            if (e.message === 'LamportWalletManager::_buyNew:: EOA does not have enough funds to cover the cost of the wallet even before considering gas') {
                alert('You do not have enough funds to purchase an Anchor Wallet on this network.')
            }
        }
        setTransactionInFlight(false)
        dispatch(setActiveStep(4))
        LogEvent("Created New Wallet")
    }

    /**
     * @name onFileInputChange
     * @description when the key file changes, take these actions
     * @author William Doyle 
     * @date November 21st 2022
     */
    async function onFileInputChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        const file: string | undefined = await e.target.files?.item(0)?.text()

        if (file === undefined)
            throw new Error('Key File is undefined')

        const t = LamportWalletManager.fromJSON(file)
        dispatch(setWalletFile(t.toJSON()))
        navigate('/home')
    }

    return (
        <>
            <NewWalletOverlay
                chain={chain}
                setChain={setChain}
                signer={signer}
                setSigner={setSigner}
                transactionInFlight={transactionInFlight}
                handleCreateNewWallet={handleCreateNewWallet}
                setShowNewWalletOverlay={setShowNewWalletOverlay}
                showNewWalletOverlay={showNewWalletOverlay}
                setShowSnackbar={props.setShowSnackbar}
                setSnackbarMessage={props.setSnackbarMessage}
                setTransactionInFlight={setTransactionInFlight}
            />

            <div className="connect-or-make-wallet">
                <Tooltip title="Click here to create a new Anchor Wallet">
                    <div className="centered-text" >
                        <div className="small-bottom-margin">
                            New Users
                        </div>
                        <div className="connect-or-make-wallet-flexcolumn">
                            <div
                                onClick={() => {
                                    setShowNewWalletOverlay(true)
                                    LogEvent("Clicked New Wallet Option")
                                }}
                                className="new-wallet-button"
                            >
                                <div className="new-wallet-wrap">
                                    <img className="plus-sign"
                                        src={PlusImage}
                                        alt="a plus sign"
                                    />
                                    <div className="new-wallet-txt">
                                        New
                                        <br />
                                        Wallet
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tooltip>
                <Tooltip title="Click here to connect to an existing Anchor Wallet">
                    <div className="centered-text">
                        <div className="small-bottom-margin">
                            Returning Users
                        </div>
                        <FileUploader handleFile={onFileInputChange} />
                    </div>
                </Tooltip>
            </div>

        </>
    );
};
