

import { createSlice } from '@reduxjs/toolkit'

const creationProgressSlice = createSlice({
    name: 'creationProgressSlice',
    initialState: {
        activeStep: 0,
        steps: [],
    },
    reducers: {
        setActiveStep: (state:any, action:any) => {
            if (typeof action.payload != 'number') 
                throw new Error('Expected payload to be a number')
            
            if (action.payload < 0 || action.payload > state.steps.length)
                throw new Error('Invalid step number')

            state.activeStep = action.payload
        },
        setSteps: (state:any, action:any) => {
            if (!Array.isArray(action.payload))
                throw new Error('Expected payload to be an array')
            
            state.steps = action.payload
        }
    }
})

export default creationProgressSlice
export const { setActiveStep, setSteps } = creationProgressSlice.actions
