import Constants from "../Constants"
import "./AnchorLogo.css"
import AnchorLogo from "../assets/logo.svg"

export default function AnchorWalletTextLogo() {
  return <div className="unstyled-link">
    <a
      href="https://anchorwallet.ca"
      target="_blank"
      rel="noopener noreferrer"
    >
      {/* <img src={AnchorLogo} alt="Anchor Wallet Logo" id="anchor-wallet-logo-img"  /> */}
      <div className="anchor-logo">
        Anchor
        <div className="anchor-logo-subtext">
          {`${Constants.slogan.toUpperCase()}`}
        </div>
      </div>

    </a>
  </div>
}
