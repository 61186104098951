import React from 'react'
import { ethers } from 'ethers'

// get the user address
// lookup ens name
// if found return ens name
// return address
export default function useUsername() {
    const [provider, setProvider] = React.useState<ethers.providers.Web3Provider | null>(null)
    const [ethereumAlwaysProvider, setEthereumAlwaysProvider] = React.useState<ethers.providers.BaseProvider>(ethers.getDefaultProvider('homestead'))
    const [name, setName] = React.useState<string>('')

    // When provider changes, update address and name
    React.useEffect(() => {
        if (provider === null) 
            return
        (async () => {
            await provider.send("eth_requestAccounts", [])
            const _address = await provider.getSigner().getAddress()
            const lookupResult: string | null = await ethereumAlwaysProvider.lookupAddress(_address)

            console.log('lookupResult', lookupResult)

            if (lookupResult === null) {
                setName(_address)
                return
            }

            setName(lookupResult)
        })()
    }, [provider, ethereumAlwaysProvider])

    // // when window changes, update provider
    React.useEffect(() => {
        setProvider(new ethers.providers.Web3Provider((window as any).ethereum))
    // }, [(window as any).ethereum])
    }, [])





    return name 
}