
export default function ErrorPage() {
    return <div>
        <h1>Something bad was about to happen so I brought you here to stop you</h1>;
        <h2>Possable things that might have brought you here</h2>
        <ul>
            <li>
                You tried to inteact with an ERC-20 contract that is not reporting its decimals as expected
            </li>

        </ul>
    </div>
}