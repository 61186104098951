import React from "react";
import useLamportWalletManager from "./useLamportWalletManager";
import useLamportWalletUpdates, { WalletMutationEvent } from "./useLamportWalletUpdates";

export type CurrencyInfo = {
    name: string,
    symbol: string,
    balance: string,
    address: string,
    decimals: string,
}

/**
 * @name useLwmErc20Info
 * @description custom hook to get ERC20 token info from the LamportWalletManager 
 * @author William Doyle
 * @date November 21st 2022 
 */
export default function useLwmErc20Info(): CurrencyInfo[] {
    const lwm = useLamportWalletManager()

    const [contracts, setContracts] = React.useState<string[]>([])
    const [currencyInfo, setCurrencyInfo] = React.useState<CurrencyInfo[]>([])

    const walletMutationEvents : WalletMutationEvent[] = useLamportWalletUpdates()

    // when lwm changes >>> get contracts
    React.useEffect(() => {
        (async () => {
            if (lwm === null) {
                setContracts([])
                return
            }
            setContracts(lwm.state.currency_contracts)
        })()
    }, [lwm])

    // when contracts change >>> get balances
    React.useEffect(() => {
        (async () => {
            if (lwm === null) {
                setCurrencyInfo([])
                return
            }

            const info = await Promise.all((contracts ?? []).map(async (caddress: string) => {
                const cinfo = await lwm.getCurrencyInfo(caddress)
                const decimals = await lwm.getDecimalsOfERC20(caddress)
                return {
                    name: cinfo[0],
                    symbol: cinfo[1],
                    balance: cinfo[2].toString(),
                    address: caddress,
                    decimals: decimals,
                }
            }))
            setCurrencyInfo(info)
        })()
    }, [contracts, lwm, walletMutationEvents])

    return currencyInfo
}