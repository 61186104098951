import { Button, Snackbar } from "@mui/material";
import React from "react";
import useLamportWalletManager from "../../hooks/useLamportWalletManager";
import useOnClickOutside from "../../hooks/useOnClickOutside";

type ReceiveOverlayParams = {
    setShowOverlay: any,
    showOverlay: boolean,
}

/**
     * @name ReceiveOverlay
     * @description Show the address to receive funds
     * @author William Doyle
     * @date November 24th 2022
     */
export default function ReceiveOverlay(props: ReceiveOverlayParams) {
    const wrapperRef = React.useRef<HTMLDivElement | null>(null)
    useOnClickOutside(wrapperRef, () => props.setShowOverlay(false))
    const lwm = useLamportWalletManager()
    const [open, setOpen] = React.useState(false);

    if (props.showOverlay === false)
        return null

    return <div className='shadow'>
        <div ref={wrapperRef} className="overlay">
            <div className="overlay-content">
            <div className="column">
                <h3>Receive Tokens</h3>
                {lwm?.state?.walletAddress ?? 'loading...'}
                <br />
                <img src={lwm?.addressQRCodeURL} alt={`a qr code which when scanned will resolve to wallet address`} />
                <br />
                <Button variant="contained" onClick={() => {
                    const contractWalletAddress = lwm?.state?.walletAddress

                    if (contractWalletAddress === undefined)
                        throw new Error('Contract Wallet Address is undefined')

                    navigator.clipboard.writeText(contractWalletAddress)
                    setOpen(true)
                }}>Click To Copy Address</Button>
            </div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                message={`Address Copied To Clipboard!`}
            />
        </div>
        </div>
    </div>
}
