import React from "react"
import { ethers } from "ethers"
import MetamaskHelper from "../MetamaskHelper"
import { supportedBlockchains } from "lamportwalletmanager/src"
import { isAddress } from "ethers/lib/utils.js"


export default function Pull() {
    const [senderAddress, setSenderAddress] = React.useState<string>("")
    const [senderIsAddress, setSenderIsAddress] = React.useState<boolean>(false)
    const [senderIsContract, setSenderIsContract] = React.useState<boolean>(false)
    const [signer, setSigner] = React.useState<ethers.providers.JsonRpcSigner | undefined>(undefined)
    const [balanceAvailable, setBalanceAvailable] = React.useState<string>("0")
    const [userAddress, setUserAddress] = React.useState<string>("")


    React.useEffect(() => {
        if (senderIsAddress === false) {
            return
        }
        const getIsContract = async () => {
            const contract = new ethers.Contract(senderAddress, ["function withdrawPayments(address) view returns (uint256)"], signer)
            const code = await contract.provider.getCode(senderAddress)
            if (code === "0x") {
                setSenderIsContract(false)
                return
            }
            setSenderIsContract(true)
        }
        getIsContract()

    }, [senderIsAddress, signer, senderAddress])

    React.useEffect(() => {
        const getSigner = async () => {
            const chain = supportedBlockchains.find((chain) => chain.name === "mumbai")
            if (chain === undefined)
                throw new Error('Chain is undefined')
            const signer = await MetamaskHelper.connect(chain)
            setSigner(signer)
        }
        getSigner()
    }, [signer])

    React.useEffect(() => {

        const getUserAddress = async () => {
            if (signer === undefined) {
                return
            }
            const userAddress = await signer.getAddress()
            setUserAddress(userAddress)
        }
        getUserAddress()
    }, [signer])

    React.useEffect(() => {
        if (ethers.utils.isAddress(senderAddress)) {
            setSenderIsAddress(true)
        }
    }, [senderAddress])

    React.useEffect(() => {
        if (senderIsAddress === false) {
            return
        }

        const getBalance = async () => {
            const contract = new ethers.Contract(senderAddress, ["function withdrawPayments(address) view returns (uint256)"], signer)
            const balance = await contract.payments(signer?.getAddress())
            setBalanceAvailable(balance.toString())
        }
        getBalance()
    }, [senderIsAddress, signer, senderAddress])

    const withdraw = React.useCallback(async () => {
        const contract = new ethers.Contract(senderAddress, ["function withdraw()"], signer)
        const tx = await contract.withdraw()
        await tx.wait()
    }, [senderAddress, signer])

    return <div
        className="faq-page"
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
        <div
            style={{
                width: '50%',
            }}>
            <h3>
                Pauli Group Presents:
            </h3>
            <h1>
                Pull Payments
            </h1>

            <div
                style={{
                    backgroundColor: 'lightpink',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid black',
                    margin: '10px',
                    padding: '10px',
                    borderRadius: '10px',
                }}>
                <input
                    type="text"
                    placeholder="Enter Sender Address"
                    value={senderAddress}
                    onChange={(e) => setSenderAddress(e.target.value)}

                />
                {/* <table border={1}> */}
                <table>
                    <tr>
                        <td>
                            is address
                        </td>
                        <td>
                            is contract
                        </td>
                        <td>
                            supports pull payments
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {senderIsAddress ? "✅" : "❌"}
                        </td>
                        <td>
                            {
                                senderIsContract ? "✅" : "❌"
                            }
                        </td>
                        <td>
                            ❓
                        </td>
                        {/* <td>
                            {senderSupportsPullPayments ? "✅" : "❌"}
                        </td> */}
                    </tr>
                </table>

                <div>
                    Balance available: {balanceAvailable}
                </div>
                <div>
                    Your Address: {userAddress}
                </div>
                <div>
                    <button
                        onClick={withdraw}
                    >
                        Withdraw
                    </button>
                </div>
            </div>
        </div>
    </div>
}

