import useLamportWalletManager from "./useLamportWalletManager"
import React from "react";
import { ethers } from "ethers"
import MetamaskHelper from "../MetamaskHelper";
import type { ChainData } from "../FigmaComponets/ConnectOrMakeWallet";
import supportedBlockchains  from "lamportwalletmanager/supportedBlockchains.json";

export type TxReceiptAndBlock = {
    receipt: ethers.providers.TransactionReceipt | null,
    block: ethers.providers.Block | null,
}

export default function useTxHistory(): TxReceiptAndBlock[] {
    const lwm = useLamportWalletManager()

    const [txHistory, setTxHistory] = React.useState<TxReceiptAndBlock[]>([])

    React.useEffect(() => {
        (async () => {
            if (lwm === null || (lwm.state.tx_hashes ?? []).length === 0) {
                setTxHistory([])
                return
            }

            const chaindata : ChainData | undefined = supportedBlockchains.find((chain) => chain.chainid === lwm.state.chainId)
            if (chaindata === undefined) {
                setTxHistory([])
                return
            }

            const signer = await MetamaskHelper.connect(chaindata)
            const provider = signer.provider
            const txReceipts = await Promise.all(lwm.state.tx_hashes.reverse().map(async (txhash) => {
                const receipt = await provider.getTransactionReceipt(txhash)
                return {
                    receipt,
                    block: await (async () => {
                        if (receipt?.blockNumber === undefined) {
                            return null
                        }
                        return await provider.getBlock(receipt.blockNumber)
                    })(),
                } as TxReceiptAndBlock
            }) )
            setTxHistory(txReceipts)
        })()
    }, [lwm])

    return txHistory
}