import LambMessageVerifier from "../Componets/LambMessageVerifier";

/**
 * @name VerifyMessagePage
 * @description This page is used to verify a signed message from LAMB.
 * @date Febuary 27th 2023
 * @author William Doyle 
 */
export default function VerifyMessagePage () {
    return <div>
        <h1>Verify LAMB Signed Message</h1>
        <LambMessageVerifier />
    </div>
}