import { Button, Step, StepLabel, Stepper } from "@mui/material"
import { ethers } from "ethers"
import { supportedBlockchains } from "lamportwalletmanager/src"
import React from "react"
// import { Stepper } from "react-form-stepper"
import { useSelector } from "react-redux"
import { ChainData } from "../../FigmaComponets/ConnectOrMakeWallet"
import { TopLine } from "../../FigmaComponets/TopLine"
import downloadToFile from "../../functions/downloadToFile"
import useLamportWalletManager from "../../hooks/useLamportWalletManager"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import MetamaskHelper from "../../MetamaskHelper"
import { useAppDispatch } from "../../redux/hooks"
import { setActiveStep, setSteps } from "../../redux/slices/creationProgressSlice"
import { setWalletFile } from "../../redux/slices/walletFileSlice"
import { AppDispatch } from "../../redux/store"
import StepperStyleConfig from "../../StepperStyleConfig"
// import AddressInput from "./AddressInput"

type AddCurrencyOverlayProps = {
    setShowOverlay: any,
    showOverlay: boolean,
    setSnackbarMessage: any,
    setShowSnackbar: any,
    chain: ChainData
}

export const addCurrencyOverlaySteps = [
    {
        label: 'Enter ERC-20 Contract Address',
    },
    {
        label: 'Save File',
    },
    {
        label: 'Continue',
    }
]

type Erc20Details = {
    name: string,
    symbol: string,
    balance: string,
    totalSupply: string,
    decimals: number,
}

const defaultErc20Details: Erc20Details = {
    name: '',
    symbol: '',
    balance: '',
    totalSupply: '',
    decimals: 0,
}

// const displayFormatted = (details : Erc20Details, value: string) =>  ethers.utils.formatUnits(value, details.decimals)

/**
 * @name AddCurrencyOverlay
 * @description an overlay with a textbox and a button to add a currency contract to to the file
 * @author William Doyle
 * @date November 25th 2022
 */
export default function AddCurrencyOverlay(props: AddCurrencyOverlayProps) {
    const wrapperRef = React.useRef<HTMLDivElement | null>(null)
    useOnClickOutside(wrapperRef, () => props.setShowOverlay(false))
    const lwm = useLamportWalletManager()
    const dispatch: AppDispatch = useAppDispatch()

    const [contractAddress, setContractAddress] = React.useState<string>('')
    const [haveValidAddress, setHaveValidAddress] = React.useState<boolean>(false)
    const [erc20Details, setErc20Details] = React.useState<Erc20Details>(defaultErc20Details)

    const activeStep = useSelector((state: any) => state.creationProgress.activeStep)
    const steps = useSelector((state: any) => state.creationProgress.steps)

    // When haveValidAddress changes, fetch the ERC-20 details
    React.useEffect(() => {
        if (haveValidAddress === false)
            return

        if (lwm === null)
            return
        (async () => {
            const [name, symbol, balance] = await lwm.getCurrencyInfo(contractAddress)
            const totalSupply = await lwm.getTotalSupply(contractAddress)
            const decimals = await lwm.getDecimals(contractAddress)

            console.log(`total supply: ${totalSupply}`)
            setErc20Details({ name, symbol, balance, totalSupply, decimals })

        })()
    }, [haveValidAddress, contractAddress, lwm])

    /**
     *  @name handleSetCurrencyAddress
     *  @description set the contract address after input validation
     *  @author William Doyle
     *  @date January 26th 2023 
     */
    async function handleSetCurrencyAddress(input: string) {
        setContractAddress(input)

        // if input is not an address, return
        if (ethers.utils.isAddress(input) === false) {
            console.log('not an address')
            setHaveValidAddress(false)
            return
        }

        if (lwm === null)
            return

        // ENSURE ADDRESS IS A CONTRACT
        // const provider = new ethers.providers.Web3Provider((window as any).ethereum)
        // const code = await provider.getStorageAt(contractAddress, 0)

        // if (code === '0x') {
        //     props.setSnackbarMessage(`No code at address ${contractAddress}`)
        //     props.setShowSnackbar(true)
        //     return
        // }
        setHaveValidAddress(true)
    }

    function NextButton(props: { isDisabled: boolean }) {
        return <Button disabled={props.isDisabled} variant="contained" onClick={() => dispatch(setActiveStep(activeStep + 1))}>Next</Button>
    }

    if (props.showOverlay === false)
        return null

    return <div className='shadow'>
        <div ref={wrapperRef} className="overlay">
            <div className="overlay-content">
                <h3>Import ERC-20 Contract</h3>
                {/* <Stepper
                    activeStep={activeStep}
                    steps={steps}
                    styleConfig={StepperStyleConfig}
                /> */}
                <Stepper alternativeLabel activeStep={activeStep} >
                    {addCurrencyOverlaySteps.map((step: { label: string }) => (
                        <Step key={step.label}>
                            <StepLabel >{step.label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {/* <h4>Here you can add a currency contract to your wallet</h4> */}
                <br />
                <div className="column">
                    {
                        (() => {
                            if (steps[activeStep].label === 'Enter ERC-20 Contract Address')
                                return <div className="column">
                                    <label htmlFor="add-currency-address-input">Enter ERC-20 Contract Address</label>
                                    <br />
                                    <input id="txt-address-erc-20-contract" type="text" value={contractAddress} onChange={(e) => handleSetCurrencyAddress(e.target.value)} />
                                    <br />
                                    <NextButton isDisabled={(!haveValidAddress) || (erc20Details.totalSupply === '') } />
                                </div>
                        })()
                    }

                    {
                        (() => {
                            if (steps[activeStep].label === 'Save File')
                                return <div className="column" style={{ textAlign: 'center' }}>
                                    {/* 
                                    review currency details
                                     */}
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Symbol</th>
                                                <th>Balance</th>
                                                <th>Total Supply</th>
                                                <th>Decimals</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {erc20Details.name}
                                                </td>
                                                <td>
                                                    {erc20Details.symbol}
                                                </td>
                                                <td>
                                                    {/* {erc20Details.balance} */}
                                                    {ethers.utils.formatUnits(erc20Details.balance, erc20Details.decimals)}
                                                </td>
                                                <td>
                                                    {/* {erc20Details.totalSupply} */}
                                                    {ethers.utils.formatUnits(erc20Details.totalSupply, erc20Details.decimals)}
                                                </td>
                                                <td>
                                                    {erc20Details.decimals}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <Button variant="contained" color="primary" onClick={() => {
                                        if (lwm === null)
                                            return console.log('lwm is null')

                                        // add currency to wallet 
                                        lwm.addCurrency(contractAddress)

                                        // update redux
                                        dispatch(setWalletFile(lwm.toJSON()))

                                        // save file
                                        downloadToFile(lwm.toJSON(), `ANCHOR_${props.chain.name}_${lwm.state.walletAddress}.json`, 'application/json')

                                        // move to next step
                                        dispatch(setActiveStep(activeStep + 1))
                                    }}>
                                        Save File
                                    </Button>
                                </div>
                        })()
                    }

                    {
                        (() => {
                            // if on continue screen --> show dismis button
                            if (steps[activeStep].label === 'Continue')
                                return <div className="column" style={{ textAlign: 'center' }}>
                                    <h3>
                                        Added {erc20Details.name} To Your List Of Tracked Currencies
                                    </h3>
                                    <div style={{ width: '100%' }}>
                                        <Button variant="contained" color="primary" onClick={() => {
                                            props.setShowOverlay(false)
                                        }}>
                                            Dismiss
                                        </Button>
                                    </div>
                                </div>

                        })()
                    }
                </div>
                {/* 
                <Button variant="contained" color="primary" onClick={async () => {
                    if (lwm === null)
                        throw new Error('LamportWalletManager is null')

                    if (ethers.utils.isAddress(contractAddress) === false) {
                        props.setSnackbarMessage('Invalid EVM address')
                        props.setShowSnackbar(true)
                        return
                    }

                    // ensure there is code at the address
                    const provider = ethers.getDefaultProvider(lwm.state.network_provider_url)
                    const code = await provider.getCode(contractAddress)

                    if (code === '0x') {
                        props.setSnackbarMessage(`No code at address ${contractAddress}`)
                        props.setShowSnackbar(true)
                        return
                    }


                    lwm.addCurrency(contractAddress)

                    // update redux
                    dispatch(setWalletFile(lwm.toJSON()))

                    const chainname = supportedBlockchains.find((chain) => chain.chainid === lwm.state.chainId)?.name
                    if (chainname === undefined)
                        throw new Error('Chainname is undefined')

                    const fname = `ANCHOR_${chainname}_${lwm.state.walletAddress}.json`

                    {
                        // save temp file
                        const body: string = lwm.toJSON()
                        downloadToFile(body, fname, 'application/json')
                    }

                    props.setShowOverlay(false)

                }}>Import ERC-20 Contract</Button> */}
            </div>
        </div>
    </div>
}
