import React from "react";
import useLamportWalletManager from "./useLamportWalletManager"

export type PkhCheck = {
    fromState: string | null,
    fromChain: string | null,
    same: boolean
}

/**
 *  @name usePKH
 *  @description custom hook to get the public key hash from the LamportWalletManager from both sources
 *  @author William Doyle
 *  @date November 22st 2022 
 */
export default function usePKH(): PkhCheck {
    const lwm = useLamportWalletManager()
    const [fromChain, setFromChain] = React.useState<string | null>(null)

    React.useEffect(() => {
        (async () => {
            if (lwm === null) {
                setFromChain(null)
                return
            }

            setFromChain(await lwm.pkh_fromChain())
        })()
    }, [lwm])

    return {
        fromState: lwm?.state?.kt?.pkh ?? null,
        fromChain: fromChain,
        same: lwm?.state?.kt?.pkh === fromChain
    } as PkhCheck
}