import type { ChainData } from './FigmaComponets/ConnectOrMakeWallet'
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'

export default class MetamaskHelper {
    static isInstalled(): boolean {
        return typeof (window as any).ethereum !== 'undefined'
    }

    // static hasChain(chainId: number): boolean {

    //     return false
    // }

    static async connect(chainData: ChainData): Promise<ethers.providers.JsonRpcSigner> {
        await (window as any).ethereum.request({ method: 'eth_requestAccounts' });

        const chainIdToConnect = `0x${BigNumber(parseInt(chainData.chainid)).toString(16)}`

        await (window as any).ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainIdToConnect }]
        }).catch(async (error: any) => {
            console.log(`Error: Could not switch to chain ${chainIdToConnect}`)

            if (error.code !== 4902) {
                console.error(`Error not 4902. I only handle 4902. Refusing to handle error and throwing again.`)
                throw error
            }

            return await (window as any).ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: chainIdToConnect,
                    chainName: chainData.name,
                    rpcUrls: [chainData.rpc],
                }]
            })
        })

        // await (window as any).ethereum.enable()
        const provider = new ethers.providers.Web3Provider((window as any).ethereum)
        const signer = provider.getSigner()
        return signer
    }

}