import React from 'react';
import { ethers } from 'ethers';
import useLamportWalletManager from '../hooks/useLamportWalletManager';
import LamportWalletManager, { supportedBlockchains } from 'lamportwalletmanager/src';
import { setWalletFile } from '../redux/slices/walletFileSlice';
import { AppDispatch } from "../redux/store";

import { useAppDispatch } from "../redux/hooks";
import FileUploader from '../Componets/FileUploader';
import "./ListenerExperiment.css";
import useLamportWalletUpdates from '../hooks/useLamportWalletUpdates';


/**
 *  @name ListenerExperiment
 *  @description A page with a rectangle of text that shows events as they happen on the contract. As new events are added the old ones scroll up.
 *  @date December 1st 2022
 *  @author William Doyle
 * */
export default function ListenerExperiment() {
    const lwm = useLamportWalletManager();
    const events = useLamportWalletUpdates();

    const dispactch: AppDispatch = useAppDispatch()


    async function onFileInputChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        const file: string | undefined = await e.target.files?.item(0)?.text()

        if (file === undefined)
            throw new Error('Key File is undefined')

        const t = LamportWalletManager.fromJSON(file)
        dispactch(setWalletFile(t.toJSON()))
    }



    return <div className='listener-experiment'>
        <h1>
            Listener Experiment
        </h1>
        <FileUploader handleFile={onFileInputChange} />

        {/* <h3> Data from {lwm?.state.walletAddress} on chain {chain.name}</h3> */}
        <h3> Data from {lwm?.state.walletAddress} </h3>

        <div style={{
            whiteSpace: "pre-wrap",
            border: "1px solid black",
            borderRadius: "10px",
            padding: "10px",
            margin: "10px",
        }}>
            {
                // JSON.stringify(lwm?.state, null, 2)
                JSON.stringify(events, null, 2)
            }
        </div>
    </div>
}
