import React from "react";
// import ClipLoader from "react-spinners/ClipLoader";
import useLamportWalletManager from "../hooks/useLamportWalletManager";
import supportedBlockchains from "lamportwalletmanager/supportedBlockchains.json";
import lockGif from "../assets/lock.gif"
import { Tooltip } from "@mui/material";

// const override: React.CSSProperties = {
//     display: "block",
//     margin: "0 auto",
//     borderColor: "red",
// };

type Params = {
    txid: string | null
}

export default function TransactionInFlightIndicator(params: Params) {
    const lwm = useLamportWalletManager()
    const [txExplorerUrl, setTxExplorerUrl] = React.useState<string>('')

    React.useEffect(() => {

        const chainid = lwm?.state?.chainId
        if (!chainid)
            return

        const chain = supportedBlockchains.find(c => c.chainid === chainid)

        if (!chain)
            return

        setTxExplorerUrl(`${chain.explorer}/tx/${params.txid}`)
    }, [params.txid, lwm])

    return <div className="column" style={{
        alignItems: "center",
    }} >
        <h1>Please Wait for Transaction To Settle</h1>
        <div style={{ color: `orange` }}>
            <h2>Important: Save The New Data File When Prompted!</h2>
        </div>

        {/* <ClipLoader
            color={'#aa0000'}
            loading={true}
            cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
        /> */}
        <img src={lockGif} alt="Loading Spinner" style={{ width: 150, height: 150 }} />

        <Tooltip title="View In Explorer" >
            <div className="small-margin">
                <div className="unstyled-link">
                    <a href={txExplorerUrl} target="_blank" rel="noopener noreferrer">
                        {
                            params.txid
                        }
                    </a>
                </div>
            </div>
        </Tooltip>

        <div style={{ color: `orange` }}>
            <h3>Once the transaction has settled please exit and re-login with your newest key file</h3>
        </div>
    </div>
}