import React from "react";
import useLamportWalletManager from "./useLamportWalletManager";
import useLamportWalletUpdates, { WalletMutationEvent } from "./useLamportWalletUpdates";

export type NFTInfo = {
    name: string,
    symbol: string,
    balance: string,
    address: string,
    totalSupply: string,
}

/**
 * @name useLwmErc721Info
 * @description custom hook to get ERC721 info from the LamportWalletManager 
 * @author William Doyle
 * @date November 21st 2022
 */
export default function useLwmErc721Info(): NFTInfo[] {
    const lwm = useLamportWalletManager()

    const [contracts, setContracts] = React.useState<string[]>([])
    const [nftInfo, setNftInfo] = React.useState<NFTInfo[]>([])
    const walletMutationEvents: WalletMutationEvent[] = useLamportWalletUpdates()

    // when lwm changes >>> get contracts
    React.useEffect(() => {
        (async () => {
            if (lwm === null) {
                setContracts([])
                return
            }
            setContracts(lwm.state.nft_contracts)
        })()
    }, [lwm])

    // when contracts change >>> get balances
    React.useEffect(() => {
        (async () => {
            if (lwm === null || (contracts?.length ?? 0) === 0) {
                setNftInfo([])
                return
            }

            const info = await Promise.all(contracts.map(async (caddress: string) => {
                const cinfo = await lwm.getNFTInfo(caddress)
                const totalSupply: string = await lwm.getTotalSupply(caddress)
                    .catch((e) => "NA")

                return {
                    name: cinfo[0],
                    symbol: cinfo[1],
                    balance: cinfo[2].toString(),
                    address: caddress,
                    totalSupply: totalSupply,
                }
            }))
            setNftInfo(info)
        })()
    }, [contracts, lwm, walletMutationEvents])

    return nftInfo
}