import { Button } from "@mui/material"
import { ethers } from "ethers"
import { supportedBlockchains } from "lamportwalletmanager/src"
import { WaiterCallback } from "lamportwalletmanager/LamportWalletManager"
import React from "react"
import { ChainData } from "../../FigmaComponets/ConnectOrMakeWallet"
import downloadToFile from "../../functions/downloadToFile"
import useLamportWalletManager from "../../hooks/useLamportWalletManager"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import MetamaskHelper from "../../MetamaskHelper"
import { useAppDispatch } from "../../redux/hooks"
import { setWalletFile } from "../../redux/slices/walletFileSlice"
import { AppDispatch } from "../../redux/store"
import TransactionInFlightIndicator from "../TransactionInFlightIndicator"
import { PositiveIntegerLessThanTen } from "lamportwalletmanager/src/LamportWalletManager"
type SafetyOverlayProps = {
    setShowOverlay: any,
    showOverlay: boolean,
    setTransactionInFlight: any,
    transactionInFlight: boolean,
    chain: ChainData,

}

/**
 * @name SafetyOverlay
 * @description show opitions to set and use recovery keys
 * @author William Doyle
 * @date November 25th 2022
 */
export default function SafetyOverlay(props: SafetyOverlayProps) {
    const wrapperRef = React.useRef<HTMLDivElement | null>(null)
    useOnClickOutside(wrapperRef, () => props.setShowOverlay(false))
    const lwm = useLamportWalletManager()
    const dispatch: AppDispatch = useAppDispatch()


    if (props.showOverlay === false)
        return null


    /**
     * @date November 25th 2022
     */
    async function handleSetRecoveryKeys() {
        if (lwm === null)
            throw new Error('LamportWalletManager is null')

        lwm.setGasPayer(await MetamaskHelper.connect(props.chain))
        props.setTransactionInFlight(true)
        const waiter: WaiterCallback = await lwm.call_setTenRecoveryPKHs()

        // update redux
        dispatch(setWalletFile(lwm.toJSON()))
        const chainname = supportedBlockchains.find((chain) => chain.chainid === lwm.state.chainId)?.name
        if (chainname === undefined)
            throw new Error('Chainname is undefined')

        const fname = `ANCHOR_${chainname}_${lwm.state.walletAddress}.json`

        {
            // save temp file
            const body: string = lwm.toJSON()
            downloadToFile(body, fname, 'application/json')
        }

        const receipt: ethers.providers.TransactionReceipt = await waiter()
        console.log(receipt)
        props.setTransactionInFlight(false)
        props.setShowOverlay(false)
    }

    /**
     * @date November 25th 2022
     */
    async function handleUseRecoveryKeys() {
        if (lwm === null)
            throw new Error('LamportWalletManager is null')

        lwm.setGasPayer(await MetamaskHelper.connect(props.chain))
        props.setTransactionInFlight(true)
        // use RANDOM key
  
        // random key index must be between 0 and 9
        const randomKeyIndex = Math.floor(Math.random() * 10)

        if (randomKeyIndex < 0 || randomKeyIndex > 9)
            throw new Error('Random key index is out of range')

        const waiter: WaiterCallback = await lwm.call_recover(randomKeyIndex as PositiveIntegerLessThanTen)

        // update redux
        dispatch(setWalletFile(lwm.toJSON()))
        const chainname = supportedBlockchains.find((chain) => chain.chainid === lwm.state.chainId)?.name
        if (chainname === undefined)
            throw new Error('Chainname is undefined')

        const fname = `ANCHOR_${chainname}_${lwm.state.walletAddress}.json`

        {
            // save temp file
            const body: string = lwm.toJSON()
            downloadToFile(body, fname, 'application/json')
        }

        const receipt: ethers.providers.TransactionReceipt = await waiter()
        console.log(receipt)
        props.setTransactionInFlight(false)
        props.setShowOverlay(false)
    }


    return <div className='shadow'>
        <div ref={wrapperRef} className="overlay">
            <div className="overlay-content">
            {
                (() => {
                    if (props.transactionInFlight === true)
                        return <TransactionInFlightIndicator txid={lwm?.topTxHash ?? null} />

                    return <div className="column">
                        <h3>Setup and use recovery keys</h3>
                        <br />
                        <Button variant='contained' onClick={handleSetRecoveryKeys}>Setup Recovery Keys</Button>
                        <br />
                        <Button variant='contained' onClick={handleUseRecoveryKeys}>Use Recovery Keys</Button>
                    </div>
                })()
            }

    </div>
        </div>
    </div>
}
