import React from "react"
import { ethers } from "ethers"
import { Button } from "@mui/material"

/**
 * @name HashTextBox
 * @description a component with a text box. when text is entered in the text box the hash of the text is displayed
 * @date Febuary 22nd 2023
 * @author William Doyle
 *  */
export default function HashTextBox() {
    const [hashValue, setHashValue] = React.useState<string>("")
    const [text, setText] = React.useState<string>("")

    React.useEffect(() => {
        const _hash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(text))
        setHashValue(_hash)
    }, [text])

    return <div className="hash-text-box" style={{
        display: "flex",
        flexDirection: "column",
        borderStyle: "solid",
        margin: "10px",
        padding: "10px",
    }}>
        <h2>
            Password Generator
        </h2>
        <p>
            Type randomly in the box below to generate a password!
        </p>
        <input type="text" value={text} onChange={(e) => {
            setText(e.target.value)
        }} />
        <h2>
            {hashValue}
        </h2>
        <Button variant="contained" onClick={() => {
            navigator.clipboard.writeText(hashValue)
        }}>
            Copy to Clipboard
        </Button>
    </div>
}
