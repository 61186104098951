import { Cancel, CheckCircleOutline } from "@mui/icons-material"
import { ethers } from "ethers"
import React from "react"
import { CSSProperties } from 'react'

const wrapperStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '100vh',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    fontFamily: 'Arial, sans-serif',
}

const inputStyle: CSSProperties = {
    width: '100%',
    maxWidth: '500px',
    padding: '10px',
    margin: '10px 0',
    fontSize: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
}

const radioWrapperStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: '500px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
}

const titleStyle: CSSProperties = {
    color: '#333',
    fontSize: '24px',
    textAlign: 'center',
}



type ChainRPCs = {
    [chain: string]: string
}

const chainRPCs: ChainRPCs = {
    'Mumbai': 'https://polygon-mumbai.blockpi.network/v1/rpc/public', // Add RPC for Mumbai here
    'Polygon': 'https://polygon.llamarpc.com', // Add RPC for Polygon here
    'milkomeda C1': 'https://rpc-mainnet-cardano-evm.c1.milkomeda.com', // Add RPC for milkomeda C1 here
    'Gnosis': 'https://gnosis.blockpi.network/v1/rpc/public' // Add RPC for Gnosis here
}

/**
 * @name LambMessageVerifier
 * @description a component that allows the user to verify a message signed by the lamb wallet
 * @date Febuary 27th 2023
 * @author William Doyle
 */
export default function LambMessageVerifier() {
    const [messageToVerify, setMessageToVerify] = React.useState('')
    const [signerAddress, setSignerAddress] = React.useState('')
    const [isSigned, setIsSigned] = React.useState(false)
    const [selectedChain, setSelectedChain] = React.useState('milkomeda C1') // default chain

    React.useEffect(() => {
        (async () => {
            // ensure address is an ethereum address
            if (!ethers.utils.isAddress(signerAddress)) {
                setIsSigned(false)
                return
            }

            try {
                const provider = ethers.getDefaultProvider(chainRPCs[selectedChain])
                const iface = new ethers.utils.Interface(['function isValidSignature(bytes32 hash, bytes memory missing) public view returns (bytes4)'])

                const contract = new ethers.Contract(signerAddress, iface, provider)
                const hashedOnce = ethers.utils.keccak256(ethers.utils.solidityPack(['string'], [messageToVerify]))
                console.log(`Message Hash ${hashedOnce}`)
                const result = await contract.isValidSignature(hashedOnce, '0x')
                setIsSigned(result === '0x1626ba7e')

            } catch (error) {
                console.error(error)
                setIsSigned(false)
            }
        })()
    }, [messageToVerify, signerAddress, selectedChain])

    React.useEffect(() => {
        // Reset isSigned state when chain changes
        setIsSigned(false)
    }, [selectedChain])

    const statusStyle: CSSProperties = {
        color: isSigned ? 'green' : 'red',
        fontSize: '24px',
        fontWeight: 'bold',
        margin: '20px 0',
        textAlign: 'center',
    }

    return (
        <div style={wrapperStyle}>
            <h2 style={titleStyle}>Verify Message Signed By Lamb Wallet</h2>

            <input style={inputStyle} type="text" placeholder="Message to verify" onChange={(event) => setMessageToVerify(event.target.value)} />
            <input style={inputStyle} type="text" placeholder="Signer address" onChange={(event) => setSignerAddress(event.target.value)} />

            <div style={radioWrapperStyle}>
                {Object.keys(chainRPCs).map((chain, index) => (
                    <div key={index}>
                        <input type="radio" id={chain} name="chain" value={chain}
                            checked={selectedChain === chain}
                            onChange={(e) => setSelectedChain(e.target.value)} />
                        <label htmlFor={chain}>{chain}</label>
                    </div>
                ))}
            </div>

            <div style={statusStyle}>
                {
                    (() => {
                        if (isSigned) {
                            return <>
                                Message is endorsed <CheckCircleOutline />
                            </>
                        }
                        return <>
                            Message is not endorsed <Cancel />
                        </>
                    })()
                }
            </div>
        </div>
    )
}
