import React from "react"

/**
 *  @name useOnClickOutside
 *  @description custom hook to detect clicks outside of a component
 *  @author William Doyle
 */
export default function useOnClickOutside(ref: any, callback: () => void) {
  React.useEffect(() => {
    function onClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target))
        callback()
    }
    document.addEventListener('mousedown', onClickOutside)
    return () => document.removeEventListener('mousedown', onClickOutside)
  }, [ref, callback])
}