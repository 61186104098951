import React from "react";
import type { ChainData } from '../../FigmaComponets/ConnectOrMakeWallet'
import MetamaskHelper from "../../MetamaskHelper";
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { ConnectToMetamaskButton } from "../../FigmaComponets/ConnectToMetamaskButton";
import TransactionInFlightIndicator from "../TransactionInFlightIndicator";
import supportedBlockchains from "lamportwalletmanager/supportedBlockchains.json";
import { Button, Step, StepLabel, Stepper, Toolbar, Tooltip } from "@mui/material";
// import { Stepper } from "react-form-stepper";
import useLamportWalletManager from "../../hooks/useLamportWalletManager";
import { AppDispatch } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep, setSteps } from "../../redux/slices/creationProgressSlice";
import downloadToFile from "../../functions/downloadToFile";
import { useNavigate } from "react-router-dom";
import { WaiterCallback } from "lamportwalletmanager/src/LamportWalletManager";
import { ethers } from "ethers";
import { setWalletFile } from "../../redux/slices/walletFileSlice";
// import StepperStyleConfig from "../../StepperStyleConfig";

export type NewWalletOverlayProps = {
    setShowNewWalletOverlay: any,
    showNewWalletOverlay: boolean,
    transactionInFlight: boolean,
    chain: ChainData,
    setChain: any,
    setSigner: any,
    signer: any,
    handleCreateNewWallet: any,
    setSnackbarMessage: any,
    setShowSnackbar: any,
    setTransactionInFlight: any,
}

const capitalizeFirstLetter = (_string: string) => _string.charAt(0).toUpperCase() + _string.slice(1);
const replaceAll = (_string: string, _search: string, _replace: string) => _string.split(_search).join(_replace)
const formatChainName = (_string: string) => replaceAll(capitalizeFirstLetter(_string), '_', ' ')
const unformatChainName = (_string: string) => replaceAll(_string.toLowerCase(), ' ', '_')

const newWalletSteps = [
    {
        label: 'Terms and Conditions',
    },
    {
        label: 'Connect EOA Wallet',
    },
    {
        label: 'Initiate Transaction',
    },
    {
        label: 'Await Purchase Confirmation',
    },
    {
        // label: 'Download Keys',
        label: 'Setup Recovery Keys',
    },
    {
        label: 'Await Setup Confirmation',
    },
    {
        label: 'Launch Secure Wallet',
    }
]

/**
 * @name NewWalletOverlay
 * @description This is the overlay that appears when the user clicks the new wallet button
 * @author William Doyle
 * @date November 23rd 2022
 */
export function NewWalletOverlay(props: NewWalletOverlayProps) {
    const wrapperRef = React.useRef<HTMLDivElement | null>(null)
    useOnClickOutside(wrapperRef, () => props.setShowNewWalletOverlay(false))
    const [userDidCheckBox, setUserDidCheckBox] = React.useState<boolean>(false)
    const dropDownRef = React.useRef<HTMLSelectElement | null>(null)
    const dispatch = useDispatch<AppDispatch>()
    const activeStep = useSelector((state: any) => state.creationProgress.activeStep)
    const steps = useSelector((state: any) => state.creationProgress.steps)
    const lwm = useLamportWalletManager()
    const navigate = useNavigate()

    React.useEffect(() => {
        dispatch(setSteps(newWalletSteps))
    })

    // KEEP THE DROP DOWN IN SYNC WITH PROPS.CHAIN
    React.useEffect(() => {
        if (dropDownRef.current !== null) {
            dropDownRef.current.value = props.chain.name
        }
    }, [props.chain, props.showNewWalletOverlay])



    /**
    * @date November 25th 2022
    */
    async function handleSetRecoveryKeys() {
        if (lwm === null)
            throw new Error('LamportWalletManager is null')

        // lwm.setGasPayer(await MetamaskHelper.connect(props.chain))
        const signer = await MetamaskHelper.connect(props.chain)
        console.log('signer.provider.connection (handleSetRecoveryKeys) -> ', signer.provider.connection)
        lwm.setGasPayer(signer)
        props.setTransactionInFlight(true)
        dispatch(setActiveStep(5))
        const waiter: WaiterCallback = await lwm.call_setTenRecoveryPKHs()

        // update redux
        dispatch(setWalletFile(lwm.toJSON()))
        const chainname = supportedBlockchains.find((chain) => chain.chainid === lwm.state.chainId)?.name
        if (chainname === undefined)
            throw new Error('Chainname is undefined')

        const fname = `ANCHOR_${chainname}_${lwm.state.walletAddress}.json`

        {
            // save temp file
            const body: string = lwm.toJSON()
            downloadToFile(body, fname, 'application/json')
        }

        const receipt: ethers.providers.TransactionReceipt = await waiter()
        console.log(receipt)
        props.setTransactionInFlight(false)
        dispatch(setActiveStep(6))
    }


    if (props.showNewWalletOverlay === false)
        return null

    return <div className='shadow'>
        <div ref={wrapperRef} className="overlay">
            <div className="overlay-content">
                <div className="overlay-header">
                    <div>
                        <h2>
                            Create Anchor Secure Wallet
                        </h2>
                        {/* <h3>
                            Active Step: {activeStep}
                        </h3> */}
                    </div>
                    <div className="btn-lnk">
                        <Tooltip title="Inspect Factory Contract">
                            <a href={`${props.chain.explorer}address/${props.chain.factoryAddress}`} target="_blank" rel="noreferrer">
                                <h2>
                                    {
                                        formatChainName(props.chain.name)
                                    }
                                </h2>
                            </a>
                        </Tooltip>
                    </div>
                </div>
                {/* <Stepper
                    activeStep={activeStep}
                    steps={steps}
                    styleConfig={StepperStyleConfig}
                /> */}
                {/* ////////////////////mui stepper/////////////////////////////// */}
                <br />
                <Stepper alternativeLabel activeStep={activeStep} >
                    {newWalletSteps.map((step: { label: string }) => (
                        <Step key={step.label}>
                            <StepLabel >{step.label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {/* ////////////////////////////////////////////////////////////// */}


                {
                    (() => {
                        if (steps[activeStep].label === 'Connect EOA Wallet')
                            return <div className="create-new-wallet">
                                <h5>Connect Externally Owned Account to pay fees</h5>
                                <div className="chain-select-wrap">
                                    <select ref={dropDownRef} onChange={(e) => {
                                        const chain: ChainData = supportedBlockchains.find((chain) => chain.name === unformatChainName(e.target.value)) as ChainData
                                        props.setChain(chain)
                                    }}>
                                        {
                                            supportedBlockchains.map(bc => <option key={bc.chainid} value={bc.name}>
                                                {
                                                    (() => {
                                                        if (bc.isTestnet)
                                                            return `TESTNET: ${formatChainName(bc.name)}`
                                                        // if (bc.name === 'polygon')
                                                            // return `${formatChainName(bc.name).padEnd(100, ' ')} 🔥`
                                                        return formatChainName(bc.name)
                                                    })()
                                                }
                                            </option>)
                                        }
                                    </select>
                                </div>
                                <br />
                                {/* {
                                    (() => {
                                        if (props.chain.name === 'polygon')
                                            return <div className="polygon-promotion-notice">
                                                <h3>
                                                    Complete Survey For Promotional Reimbursement!
                                                </h3>
                                                Anchor Wallets Are Currently Being Promoted On Polygon! After Creating Your Wallet, Complete The Survey To Receive Your Promotional Reimbursement!
                                            </div>
                                    })()
                                } */}
                                <br />
                                <div className="metamask-wrap" onClick={async () => {
                                    if (MetamaskHelper.isInstalled() === false) {
                                        props.setSnackbarMessage('Metamask is not installed')
                                        console.log(`Metamask is not installed`)
                                        props.setShowSnackbar(true)
                                    }
                                    const _signer = await MetamaskHelper.connect(props.chain)
                                    console.log('signer -> ', _signer)
                                    console.log('signer.provider -> ', _signer.provider)
                                    console.log('signer.provider.connection-> ', _signer.provider.connection)
                                    props.setSigner(_signer)
                                    dispatch(setActiveStep(2))
                                }} >
                                    <ConnectToMetamaskButton />
                                </div>
                            </div>

                        if (steps[activeStep].label === 'Terms and Conditions')
                            return <div className="create-new-wallet">
                                <div className="checkbox-did-read-wrap">
                                    <input
                                        type="checkbox"
                                        id="terms"
                                        name="terms"
                                        checked={userDidCheckBox}
                                        onChange={(e: any) => {
                                            setUserDidCheckBox(!userDidCheckBox);
                                            dispatch(setActiveStep(1))
                                        }} />
                                    <label htmlFor="terms">I have read and agree to the <a href="https://anchorwallet.ca/terms-of-use/" target="_blank" rel="noopener noreferrer">terms of service</a></label>
                                </div>
                            </div>

                        if (steps[activeStep].label === 'Initiate Transaction')
                            return <div className="create-new-wallet">
                                <div style={{
                                    display: `flex`,
                                    margin: `10px`
                                }}>
                                    <Button variant="contained" color="primary" disabled={(props.signer === null) || (!userDidCheckBox)} onClick={props.handleCreateNewWallet}>
                                        Create For {props.chain.price} {props.chain.currencyTicker}
                                    </Button>
                                </div>
                            </div>

                        if (((steps[activeStep].label === 'Await Setup Confirmation') || (steps[activeStep].label === "Await Purchase Confirmation") ) && (props.transactionInFlight))
                            return <div className="create-new-wallet">
                                <TransactionInFlightIndicator txid={null} />
                            </div>

                        // if (steps[activeStep].label === 'Download Keys')
                        //     return <div className="create-new-wallet">
                        //         <Button variant="contained" color="primary" onClick={() => {
                        //             if (lwm === null)
                        //                 return console.log(`lwm is null`)

                        //             downloadToFile(lwm.toJSON(), `ANCHOR_${props.chain.name}_${lwm.state.walletAddress}.json`, 'application/json')
                        //             dispatch(setActiveStep(5))
                        //         }}>
                        //             Download Keys
                        //         </Button>
                        //     </div>

                        if (steps[activeStep].label === 'Setup Recovery Keys')
                            return <div className="create-new-wallet">
                                <Button variant='contained' onClick={handleSetRecoveryKeys}>Setup Recovery Keys</Button>
                            </div>

                        if (steps[activeStep].label === 'Launch Secure Wallet')
                            return <div className="create-new-wallet">

                                <div className="medium-margin">
                                    <div className="row-list">
                                        <div className="row-list-item">
                                            <Button variant="contained" color="primary" onClick={() => navigate('/home')}>
                                                Launch Secure Wallet
                                            </Button>
                                        </div>

                                        <div className="vertical-separator" />

                                        <div className="row-list-item">
                                            <Button variant="contained" color="primary" onClick={() => {
                                                if (lwm === null)
                                                    return console.log(`lwm is null`)

                                                downloadToFile(lwm.toJSON(), `ANCHOR_${props.chain.name}_${lwm.state.walletAddress}.json`, 'application/json')
                                            }}>
                                                Download Again
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        return <div className="create-new-wallet">
                            Nothing To Display
                        </div>
                    })()
                }

                <div className="create-new-wallet">
                    <div className="important-text" style={{ width: `450px` }}>
                        NOTE: Your private keys will never leave your browser/device. Please save all prompted data files while using this service.
                    </div>
                </div>
            </div>
        </div>
    </div>
}