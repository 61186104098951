import { supportedBlockchains } from "lamportwalletmanager/src"
import React from "react"
import { ChainData } from "../FigmaComponets/ConnectOrMakeWallet"
import useLamportWalletManager from "../hooks/useLamportWalletManager"
import MetamaskHelper from '../MetamaskHelper'
import fakeDaiAbi from "../FakeDaiAbi"

/**
 * @name MoreTokensButton
 * @description Button to mint more tokens into the users anchor wallet 
 * @author William Doyle
 * @date January 31st 2023
 */
export default function MoreTokensButton(props: {}) {
    const lwm = useLamportWalletManager()
    const [chain, setChain] = React.useState<ChainData>(supportedBlockchains[0])

    // keep chain in sync with lwm.state.chainid
    React.useEffect(() => {
        (async () => {
            if (lwm === null)
                return

            const _chain: ChainData | undefined = supportedBlockchains.find((chain) => chain.chainid === lwm.state.chainId)

            if (_chain === undefined)
                return

            setChain(_chain)
        })()
    }, [lwm])

    return <button onClick={async () => {
        if (lwm === null)
            return
        // 1. get nft contract
        const options = [
            '0x4972838dDEED2accEf533BFb45e8121c5Fa7c864',
            '0x34992de82775D3ea8d0FCEecf0D0aA734eed90Fe',
            '0x72Bd1982693f294f7aaa466d024e3c1B370355BF',
        ]

        const randomOption = options[Math.floor(Math.random() * options.length)]
        const contract = lwm.getErc721Contract(randomOption)

        // 2. get signer
        const signer = await MetamaskHelper.connect(chain)
        const contractWithSigner = contract.connect(signer)

        // 3. award to self
        const myAddress = lwm.address
        const tx = await contractWithSigner.awardItem(myAddress, {
            gasLimit: await contractWithSigner.estimateGas.awardItem(myAddress),
            gasPrice: signer.getGasPrice(),
        })
        console.log(`tx pending ${tx.hash}`)

        // 4. wait for tx to be mined
        const receipt = await tx.wait()
        console.log(`tx mined ${receipt.transactionHash}`)
    }}>
        Mint Dummy NFTs
    </button>

}

export function MoreCoinsButton(props: {}) {
    const lwm = useLamportWalletManager()
    const [chain, setChain] = React.useState<ChainData>(supportedBlockchains[0])



    // keep chain in sync with lwm.state.chainid
    React.useEffect(() => {
        (async () => {
            if (lwm === null)
                return

            const _chain: ChainData | undefined = supportedBlockchains.find((chain) => chain.chainid === lwm.state.chainId)

            if (_chain === undefined)
                return

            setChain(_chain)
        })()
    }, [lwm])

    return <button onClick={async () => {
        if (lwm === null)
            return
        // 1. get nft contract
            
        const contract = lwm.getErc20Contract('0xb2c4d0111Ab40bdB414daeE2e3F53c8e2f7254Ec', fakeDaiAbi )

        // 2. get signer
        const signer = await MetamaskHelper.connect(chain)
        const contractWithSigner = contract.connect(signer)

        // 3. award to self
        const tx = await contractWithSigner.mint(lwm.address, {
            gasLimit: await contractWithSigner.estimateGas.mint(lwm.address),
            gasPrice: signer.getGasPrice(),
        })

        // await contractWithSigner.mint('0xD665d08C743254B07B45FD6BC5dE2622AdaF0535', {
        //     gasLimit: await contractWithSigner.estimateGas.mint('0xD665d08C743254B07B45FD6BC5dE2622AdaF0535'),
        //     gasPrice: signer.getGasPrice(),
        // })
        console.log(`tx pending ${tx.hash}`)

        // 4. wait for tx to be mined
        const receipt = await tx.wait()
        console.log(`tx mined ${receipt.transactionHash}`)
    }}>
        Mint Fake Currency
    </button>

}

export {
    MoreTokensButton,
}