import React from 'react'
import Top from '../Componets/Top'
import { TopLine } from '../FigmaComponets/TopLine'
import useLamportWalletManager from '../hooks/useLamportWalletManager'
import useLwmErc20Info, { CurrencyInfo } from '../hooks/useLwmErc20Info'
import useLwmErc721Info from '../hooks/useLwmErc721Info'
import useLwmEthBalance from '../hooks/useLwmEthBalance'
import { ethers } from 'ethers'
import usePKH, { PkhCheck } from '../hooks/usePKH'
import type { ChainData } from '../FigmaComponets/ConnectOrMakeWallet'
import { Footer } from '../FigmaComponets/Footer'

import supportedBlockchains from "lamportwalletmanager/supportedBlockchains.json";
import { Avatar, Button, IconButton, Snackbar, Tooltip } from '@mui/material'

import SendIcon from "@mui/icons-material/Send";
import { CallReceived, CheckCircleOutline, ContentCopy, ErrorOutline, HealthAndSafety, Info, ReceiptLong, Visibility } from '@mui/icons-material'
import ReceiveOverlay from '../Componets/Overlay/ReceiveOverlay'
import ActivityOverlay from '../Componets/Overlay/ActivityOverlay'
import AddCurrencyOverlay, { addCurrencyOverlaySteps } from '../Componets/Overlay/AddCurrencyOverlay'
import { SendOverlay, sendOverlaySteps } from '../Componets/Overlay/SendOverlay'
import SafetyOverlay from '../Componets/Overlay/SafetyOverlay'
import AddNFTOverlay, { addNftOverlaySteps } from '../Componets/Overlay/AddNFTOverlay'
import Constants from '../Constants'
import { AppDispatch } from '../redux/store'
import { useAppDispatch } from '../redux/hooks'
import { setActiveStep, setSteps } from "../redux/slices/creationProgressSlice";
import NftCollectionOverlay from '../Componets/Overlay/NftCollectionOverlay'
import { useNavigate } from "react-router-dom"
import MoreTokensButton, {MoreCoinsButton} from '../Componets/MoreTokensButton'
import SendNftOverlay from '../Componets/Overlay/SendNftOverlay'

export const ActivityIconStyle = { background: `linear-gradient(to right top, lightblue, ${Constants.main_color})` }

export default function HomePage() {
    const lwm = useLamportWalletManager()
    const ethBalance = useLwmEthBalance()
    const erc20CurrencyInfo = useLwmErc20Info()
    const erc721Info = useLwmErc721Info()
    const pkhCheck: PkhCheck = usePKH()

    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("")

    const [showSendOverlay, setShowSendOverlay] = React.useState<boolean>(false)
    const [chain, setChain] = React.useState<ChainData>(supportedBlockchains[0])

    const [transactionInFlight, setTransactionInFlight] = React.useState<boolean>(false)

    const [showReceiveOverlay, setShowReceiveOverlay] = React.useState<boolean>(false)
    const [showActivityOverlay, setShowActivityOverlay] = React.useState<boolean>(false)
    const [showSafetyOverlay, setShowSafetyOverlay] = React.useState<boolean>(false)
    const [showAddNftOverlay, setShowAddNftOverlay] = React.useState<boolean>(false)
    const [showAddCurrencyOverlay, setShowAddCurrencyOverlay] = React.useState<boolean>(false)
    const [tokenNameOuter, setTokenNameOuter] = React.useState<string>('Native Token') // set by buttona in table /// passed down to SendOverlay
    const [showNftCollectionOverlay, setShowNftCollectionOverlay] = React.useState<boolean>(false)
    const [showSendNftOverlay, setShowSendNftOverlay] = React.useState<boolean>(false)

    const [selectedNftCollectionAddress, setSelectedNftCollectionAddress] = React.useState<string>(ethers.constants.AddressZero)

    const dispatch: AppDispatch = useAppDispatch()
    const navigate = useNavigate()

    // keep chain in sync with lwm.state.chainid
    React.useEffect(() => {
        (async () => {
            if (lwm === null)
                return

            const _chain: ChainData | undefined = supportedBlockchains.find((chain) => chain.chainid === lwm.state.chainId)

            if (_chain === undefined)
                return

            setChain(_chain)
        })()
    }, [lwm])

    /**
     * @name processTotalSupply
     * @description some 721 contracts don't have a total supply, so we need to check for "NA" and add a tooltip
     * @date Febuary 1st 2023
     * @author William Doyle
     */
    function processTotalSupply(totalSupply: string): string | JSX.Element {
        if (totalSupply !== "NA")
            return totalSupply

        return <Tooltip title="Cannot Show Total Supply Because Contract Does Not Implement ERC721Enumerable Extension">
            <span>
                <ErrorOutline sx={{ color: "grey.500" }} />
            </span>
        </Tooltip>
    }

    if (lwm === null)
        return <></>

    return <div className="HomePage">
        {
            (() => {
                // if (chain.name === "polygon") {
                //     return <div>
                //         <div className="top-promotion" onClick={() => navigate(`/promo`)}>
                //             Polygon Is On Promotion: Click Here To Complete A Survey And Receive A 0.1 MATIC Reimbursment For Your Wallet Purchase
                //         </div>
                //         <br />
                //     </div>
                // }

                if (chain.name === "mumbai") {
                    return <div >
                        <div className="top-promotion">

                            <div className="row-list">
                                <div className="row-list-item">
                                    <MoreTokensButton />
                                </div>

                                <div className="vertical-separator" />

                                <div className="row-list-item">
                                    <a href="https://mumbaifaucet.com/" target="_blank" rel="noreferrer">
                                        Get Testnet MATIC
                                    </a>
                                </div>

                                <div className="vertical-separator" />

                                  <div className="row-list-item">
                                    <MoreCoinsButton />
                                </div>
                            </div>

                        </div>
                        <br />
                    </div>
                }
            })()
        }
        <SendOverlay
            tokenNameOuter={tokenNameOuter}
            showOverlay={showSendOverlay}
            setShowOverlay={setShowSendOverlay}
            chain={chain}
            setTransactionInFlight={setTransactionInFlight}
            transactionInFlight={transactionInFlight}
        />
        <ReceiveOverlay
            showOverlay={showReceiveOverlay}
            setShowOverlay={setShowReceiveOverlay} />
        <ActivityOverlay
            showOverlay={showActivityOverlay}
            setShowOverlay={setShowActivityOverlay}
            chain={chain} />
        <SafetyOverlay
            showOverlay={showSafetyOverlay}
            setShowOverlay={setShowSafetyOverlay}
            chain={chain}
            setTransactionInFlight={setTransactionInFlight}
            transactionInFlight={transactionInFlight} />
        <AddNFTOverlay
            setShowSnackbar={setShowSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            showOverlay={showAddNftOverlay}
            setShowOverlay={setShowAddNftOverlay}
            chain={chain}
        />
        <AddCurrencyOverlay
            showOverlay={showAddCurrencyOverlay}
            setShowOverlay={setShowAddCurrencyOverlay}
            setShowSnackbar={setShowSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            chain={chain}
        />
        <NftCollectionOverlay
            showOverlay={showNftCollectionOverlay}
            setShowOverlay={setShowNftCollectionOverlay}
            collectionAddress={selectedNftCollectionAddress}
            chain={chain}
            setSnackbarMessage={setSnackbarMessage}
            setShowSnackbar={setShowSnackbar}

            setShowSendInterface={setShowSendNftOverlay}
            showSendInterface={showSendNftOverlay}

            setTransactionInFlight={setTransactionInFlight}
            transactionInFlight={transactionInFlight}
        />
        <SendNftOverlay
            showOverlay={showSendNftOverlay}
            setShowOverlay={setShowSendNftOverlay}
            setTransactionInFlight={setTransactionInFlight}
            transactionInFlight={transactionInFlight}
        />

        <Top />
        <h1>{lwm.chainName?.toUpperCase()}</h1>
        <h2>ERC-20 Tokens</h2>
        <div className="elevated-area">
            <div className="elevated-area-content" >
                <div style={{
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <div className="token-list">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <h3>
                                            Token
                                        </h3>
                                    </th>
                                    <th>
                                        <h3>
                                            Balance
                                        </h3>
                                    </th>
                                    <th>
                                        <h3>
                                            Activity
                                        </h3>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {chain.currencyTicker}
                                    </td>
                                    <td>
                                        {ethers.utils.formatEther(ethBalance ?? '0').toString()}
                                    </td>
                                    <td>
                                        <Tooltip title="Send">
                                            <IconButton color='primary' onClick={() => {
                                                dispatch(setSteps(sendOverlaySteps))
                                                dispatch(setActiveStep(0))
                                                setTokenNameOuter('Native Token')
                                                setShowSendOverlay(true)
                                            }} >
                                                <Avatar style={ActivityIconStyle}>
                                                    <SendIcon />
                                                </Avatar>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Receive">
                                            <IconButton color='primary' onClick={() => setShowReceiveOverlay(true)} >
                                                <Avatar style={ActivityIconStyle}>
                                                    <CallReceived />
                                                </Avatar>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Transaction History">
                                            <IconButton color='primary' onClick={() => setShowActivityOverlay(true)} >
                                                <Avatar style={ActivityIconStyle}>
                                                    <ReceiptLong />
                                                </Avatar>
                                            </IconButton>
                                        </Tooltip>
                                    </td>
                                </tr>
                                {
                                    erc20CurrencyInfo.map((token: CurrencyInfo, index) => {
                                        return <tr key={index}>
                                            <td>
                                                {token.name}
                                            </td>
                                            <td>
                                                {ethers.utils.formatUnits(token.balance.toString(), token.decimals).toString()}
                                            </td>
                                            <td>
                                                <IconButton color='primary' onClick={() => {
                                                    dispatch(setSteps(sendOverlaySteps))
                                                    dispatch(setActiveStep(0))
                                                    setTokenNameOuter(token.name)
                                                    setShowSendOverlay(true)
                                                }} >
                                                    <Avatar style={ActivityIconStyle}>
                                                        <Tooltip title={`Send ${token.name}`}>
                                                            <SendIcon />
                                                        </Tooltip>
                                                    </Avatar>
                                                </IconButton>
                                                <Tooltip title="Receive">
                                                    <IconButton color='primary' onClick={() => setShowReceiveOverlay(true)} >
                                                        <Avatar style={ActivityIconStyle}>
                                                            <CallReceived />
                                                        </Avatar>
                                                    </IconButton>
                                                </Tooltip>
                                                {/* <IconButton color='primary' onClick={() => setShowActivityOverlay(true)} >
                                            <ReceiptLong />
                                        </IconButton> */}
                                                <Tooltip title="Copy Address">
                                                    <IconButton color='primary' onClick={() => {
                                                        // put address of currency contract in the users clipboard
                                                        navigator.clipboard.writeText(token.address)
                                                        setSnackbarMessage(`Copied address to clipboard`)
                                                        setShowSnackbar(true)
                                                    }} >
                                                        <Avatar style={ActivityIconStyle}>
                                                            <ContentCopy />
                                                        </Avatar>
                                                    </IconButton>
                                                </Tooltip>

                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div style={{ margin: '30px' }}>
                        <Tooltip title="Add ERC-20 Token">
                            <Button variant='contained' onClick={() => {
                                dispatch(setSteps(addCurrencyOverlaySteps))
                                dispatch(setActiveStep(0))
                                setShowAddCurrencyOverlay(true)
                            }}>Import ERC-20 Contract</Button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
        <h2>ERC-721 Tokens</h2>
        <div className="elevated-area">
            <div className="elevated-area-content" >
                <div style={{
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <div className="token-list" >
                        <table >
                            <thead>
                                <tr>
                                    <th>
                                        <h3>
                                            Name
                                        </h3>
                                    </th>
                                    <th>
                                        <h3>
                                            Number Owned
                                        </h3>
                                    </th>
                                    <th>
                                        <h3>
                                            Total Supply
                                        </h3>
                                    </th>
                                    <th>
                                        <h3>
                                            Contract Address
                                        </h3>
                                    </th>
                                    <th>
                                        <h3>
                                            Manage Collection
                                        </h3>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    erc721Info.map((item, index) => {
                                        return <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>{item.balance}</td>
                                            <td>{processTotalSupply(item.totalSupply)}</td>
                                            <td>
                                                <Tooltip title="View In Explorer">
                                                    <div className="unstyled-link">
                                                        <a href={`${chain.explorer}address/${item.address}`} target="_blank" rel="noreferrer">
                                                            <div className='text-crusher'>
                                                                {item.address}
                                                            </div>
                                                        </a>
                                                    </div>
                                                </Tooltip>
                                            </td>
                                            <td>
                                                <Tooltip title="Manage Collection">
                                                    <IconButton color='primary' onClick={() => {
                                                        // 1. set collection address
                                                        setSelectedNftCollectionAddress(item.address)

                                                        // 2. show nft collection overlay
                                                        setShowNftCollectionOverlay(true)
                                                    }} >
                                                        <Avatar style={ActivityIconStyle}>
                                                            <Visibility />
                                                        </Avatar>
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div style={{ margin: '30px' }}>
                        <Tooltip title="Add ERC-721 Token">
                            <Button variant='contained' onClick={() => {
                                dispatch(setSteps(addNftOverlaySteps))
                                dispatch(setActiveStep(0))
                                setShowAddNftOverlay(true)
                            }} >Import ERC-721 Contract</Button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
        {/* 
        <h2>ERC-1155 Tokens</h2>
        <div className="elevated-area">
            <div className="elevated-area-content" >
                <div className='column'>
                    <div style={{
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <table>
                            <thead>
                                <tr>
                                    <h3>
                                        Name
                                    </h3>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div> */}

        <h2>Integrity Check</h2>
        <div className="elevated-area">
            <div className="elevated-area-content" >
                <div className='column'>
                    {
                        (() => {
                            // if (pkhCheck.fromState === pkhCheck.fromChain)
                            if (pkhCheck.same)
                                return <div className="integrity-check">
                                    <Tooltip title="Your Key File Is Up To Date">
                                        <CheckCircleOutline color='success' />
                                    </Tooltip>
                                    <p>Success</p>
                                </div>
                            return <div className="integrity-check">
                                <Tooltip title="Your Key File Is Out Of Date. Load the correct file or recover using recovery keys">
                                    <ErrorOutline color='error' />

                                </Tooltip>

                                <p>Failure</p>
                                <br />
                                from state: {pkhCheck.fromState}
                                <br />
                                from chain: {pkhCheck.fromChain}
                            </div>

                        })()
                    }
                    <Tooltip title="Manage Recovery Keys">
                        <IconButton color='primary' onClick={() => setShowSafetyOverlay(true)} >
                            <HealthAndSafety />
                        </IconButton>
                    </Tooltip>
                    <Snackbar
                        open={showSnackbar}
                        autoHideDuration={6000}
                        onClose={() => setShowSnackbar(false)}
                        message={snackbarMessage}
                    />
                </div>
            </div>
        </div>
        <br />
        <br />
        <TopLine />
        <Footer />
        <br />
    </div >
}