
import {createSlice } from '@reduxjs/toolkit'

const recipentAddressSlice = createSlice({
    name: 'recipentAddressSlice',
    initialState: {
        address: null,
    },
    reducers: {
        setRecipentAddress: (state:any, action:any) => {
            state.address = action.payload
        }
    }
})

export default recipentAddressSlice
export const { setRecipentAddress } = recipentAddressSlice.actions
