import { supportedBlockchains } from "lamportwalletmanager/src"
import factoryAbi from "lamportwalletmanager/abi/factoryabi.json"
import React from "react"
import { ethers } from "ethers"
import { Avatar, Button, Snackbar, Tooltip } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { get } from "http"
import { Cancel, CheckCircle, CheckCircleOutline } from "@mui/icons-material"
import LambMessageVerifier from "../Componets/LambMessageVerifier"
import HashTextBox from "../Componets/HashTextBox"
import getNumberOfType1ChallengesMinted from "../functions/getNumberOfType1ChallengesMinted"

type FactoryData = {
    chainName: string,
    factoryAddress: string,
    numberWalletsSold: string,
    totalNativeFunds: string,
    createdWallets: any[],
    explorer: string,
    currencyTicker: string,
    price: string,
    rpc: string,
    chainId: number | string,
}

type StringOrNumberToStringArrayMap = {
    [key: string | number]: string[]
}

const chainIdToFallbackProvider = (_chainId: number | string) => {
    const theMap: StringOrNumberToStringArrayMap = {
        1: [ // ethereum
            'https://eth.llamarpc.com',
            'https://ethereum.publicnode.com',
            'https://eth.rpc.blxrbdn.com',
        ],
        137: [ // polygon
            'https://polygon.llamarpc.com',
            'https://polygon-bor.publicnode.com',
            'https://endpoints.omniatech.io/v1/matic/mainnet/public',
        ],
        43114: [ // avalanche
            'https://avalanche.blockpi.network/v1/rpc/public',
            'https://rpc.ankr.com/avalanche',
            'https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc',
        ],
        1285: [ // moonriver
            'https://moonriver.public.blastapi.io',
        ],
        56: [ // binance smart chain
            'https://bsc.publicnode.com',
            'https://endpoints.omniatech.io/v1/bsc/mainnet/public',
            'https://bsc.meowrpc.com',
        ],
        100: [ // Gnosis
            'https://gnosis.blockpi.network/v1/rpc/public',
            'https://gnosis.api.onfinality.io/public',
            'https://gnosis-mainnet.public.blastapi.io',
        ],
        32520: [ // bitgert
            'https://mainnet-rpc.brisescan.com',
            'https://serverrpc.com',
            'https://rpc.icecreamswap.com',
        ],
        11155111: [ // sepolia
            'https://rpc.sepolia.org',
        ],
        1287: [ // moonbase
            'https://rpc.testnet.moonbeam.network',
        ],
        80001: [ // mumbai
            'https://matic-mumbai.chainstacklabs.com',
        ],
        43113: [ // fuji
            'https://api.avax-test.network/ext/bc/C/rpc',
        ]
    }
    const chainId = parseInt(_chainId.toString())
    const providers = theMap[chainId].flatMap((rpc: string) => {
        try {
            return [new ethers.providers.JsonRpcProvider(rpc)]
        }
        catch (e) {
            return []
        }
    })

    return new ethers.providers.FallbackProvider(providers)
}

export default function AdminPage() {
    const [factoryData, setFactoryData] = React.useState<FactoryData[]>([])
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("")
    const [includeTestnets, setIncludeTestnets] = React.useState(false)
    const [challengesType1TotalSupply, setChallengesType1TotalSupply] = React.useState<string>("")

    const navigate = useNavigate()

    React.useEffect(() => {
        (async () => {
            // const data: FactoryData[] = await Promise.all(supportedBlockchains.filter(c => c.isTestnet === false || includeTestnets).filter((el: any, index: number) => {
            //     if (index > 6) {
            //         return false
            //     }
            //     return true
            // }).map(async element => {

            const data: FactoryData[] = await Promise.all(supportedBlockchains.filter(c => c.isTestnet === false || includeTestnets).map(async element => {
                // const provider = new ethers.providers.JsonRpcProvider(element.rpc)
                const provider = chainIdToFallbackProvider(element.chainid)

                const factory = new ethers.Contract(element.factoryAddress, factoryAbi, provider)

                const allCreatedWallets = await factory.getAllCreatedWallets()
                console.log(allCreatedWallets)

                const balances: ethers.BigNumber[] = await Promise.all(allCreatedWallets.map(async (element: any) => await provider.getBalance(element.walletAddress)))
                const sum = balances.reduce((acc: ethers.BigNumber, curr: ethers.BigNumber) => acc.add(curr), ethers.BigNumber.from(0))

                return {
                    chainName: element.name,
                    factoryAddress: element.factoryAddress,
                    numberWalletsSold: allCreatedWallets.length,
                    totalNativeFunds: ethers.utils.formatEther(sum.toString()),
                    createdWallets: allCreatedWallets,
                    explorer: element.explorer,
                    currencyTicker: element.currencyTicker,
                    price: element.price,
                    rpc: element.rpc,
                    chainId: element.chainid,
                } as FactoryData
            }))
            setFactoryData(data)
        })()
    }, [includeTestnets])

    React.useEffect(() => {
        (async () => {
            const _ts = await getNumberOfType1ChallengesMinted()
            console.log("_ts: " + _ts)
            setChallengesType1TotalSupply(_ts)
        })();
    }, [])

    return <div className="admin-page">
        <h1>
            Admin Page
        </h1>

        {/* a switch to control 'includeTestnets' */}
        <div className="admin-switch">
            <label className="switch">
                <input type="checkbox" checked={includeTestnets} onChange={() => setIncludeTestnets(!includeTestnets)} />
                <span className="slider round"></span>
            </label>
            <span>
                Include Testnets
            </span>
        </div>

        <br />


        <table border={1} className="admin-table">
            <thead>
                <tr>
                    <th>Chain Name</th>
                    <th>Factory Address</th>
                    <th>Number Wallets Deployed</th>
                    <th>Calculate Total Protected Native Funds</th>
                    <th>Profit Address</th>
                    <th>Current Price Per Wallet</th>
                </tr>
            </thead>
            <tbody>
                {
                    factoryData.map((data, index) => {
                        return <tr key={index}>
                            <td>{data.chainName}</td>
                            <td>
                                <a href={`${data.explorer}address/${data.factoryAddress}`} target="_blank" rel="noreferrer">
                                    {data.factoryAddress}
                                </a>
                            </td>
                            <td>{data.numberWalletsSold}</td>
                            <td>
                                <Button variant="contained" onClick={async () => {
                                    // 1. calculate total native funds secured by our wallets on this chain
                                    const totalNativeFunds = await (async () => {
                                        // const provider = new ethers.providers.JsonRpcProvider(data.rpc)
                                        const provider = chainIdToFallbackProvider(data.chainId)
                                        const factory = new ethers.Contract(data.factoryAddress, factoryAbi, provider)

                                        const allCreatedWallets = await factory.getAllCreatedWallets()

                                        const balances: ethers.BigNumber[] = await Promise.all(allCreatedWallets.map(async (element: any) => await provider.getBalance(element.walletAddress)))
                                        const sum = balances.reduce((acc: ethers.BigNumber, curr: ethers.BigNumber) => acc.add(curr), ethers.BigNumber.from(0))
                                        return ethers.utils.formatEther(sum.toString())
                                    })()

                                    // 2. display with a snackbar
                                    setSnackbarMessage(`${totalNativeFunds} ${data.currencyTicker} is protected on ${data.chainName}`)
                                    setShowSnackbar(true)
                                }}>
                                    {`Calculate Total ${data.currencyTicker} Secured`}
                                </Button>
                            </td>
                            <td>
                                <Tooltip title="0x4f171744973047296d90e7828676F4972faFB200">
                                    <a
                                        href={`${data.explorer}address/0x4f171744973047296d90e7828676F4972faFB200`}
                                        target="_blank"
                                        rel="noreferrer">
                                        0x4f171...aFB200
                                    </a>
                                </Tooltip>
                            </td>
                            <td>
                                {
                                    `${data.price} ${data.currencyTicker}`
                                }
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>
        <br />
        <h2>
            total number of deployed wallets:
            <br />
            {(() => factoryData.reduce((acc, curr) => acc + parseInt(curr.numberWalletsSold), 0))()}
        </h2>

        <br />

        <Button variant="contained" onClick={() => {
            navigate('/ReleventAddresses')
        }}>
            Useful Addresses
        </Button>

        <br />


        <Button variant="contained" onClick={() => {
            navigate('/testnetassets')
        }}>
            Version 2 (LAMB) Testers Guide
        </Button>

        <br />

        <div style={{
            display: "flex",
            flexDirection: "column",
            borderStyle: "solid",
            margin: "10px",
            padding: "10px",
            backgroundImage: "linear-gradient(to bottom right, #01cdfe, #b967ff)",
            color: "#fffb96",
            textShadow: "2px 2px 3px black",
            alignItems: "center",
        }}>
            <h2>
                Proof-of-quantum
            </h2>
            <h3>
                Minted Type 1 Challenges: <span style={{ fontSize: '50px' }}>{challengesType1TotalSupply}</span>
            </h3>
            <div className="unstyled-link">
                <h3>
                    <a href="https://opensea.io/collection/proof-of-quantum-certificate" target="_blank" rel="noreferrer">
                        OpenSea
                    </a>
                </h3>
                <h3>
                    <a href="https://proof-of-quantum.com/" target="_blank" rel="noreferrer">
                        proof-of-quantum.com
                    </a>
                </h3>
            </div>
        </div>
        <br />

        <HashTextBox />

        <LambMessageVerifier />

        <Snackbar
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={() => setShowSnackbar(false)}
            message={snackbarMessage}
        />

    </div>
}






