import useUsername from "../hooks/useUsername"

export default function Playground() {
    const username = useUsername()
    return <div>
        <h1>Playground</h1>
        <table border={1}>
            <thead>
                <tr>
                    <th>Username</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{username}</td>
                </tr>
            </tbody>
        </table>
    </div>
}