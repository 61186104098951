import useLamportWalletManager from "../hooks/useLamportWalletManager"
import WalletIcon from "@mui/icons-material/Wallet";
import DisconnectButton from "./DisconnectButton";
import "./ConnectionStatus.css"
import "../App.css"
import { Avatar, Tooltip } from "@mui/material";

/**
 * @name ConnectionStatus
 * @author William Doyle
 * @description Show the connection status of the wallet in the top right corner
 */
export default function ConnectionStatus() {
    const lwm = useLamportWalletManager()

    return <div className="ConnectionStatus">
        <div className="connection-status-wrapper">
            <div className="spacer" />
            <div className="connection-status-row-maker">
                <div className="connection-status-text-holder" >
                    {
                        (() => {
                            if (lwm === null)
                                return `Wallet Not Connected`
                            return <div>
                                Connected to
                                <Tooltip title="Click To Copy">
                                    <div className="text-crusher"
                                        onClick={() => {
                                            navigator.clipboard.writeText(lwm.address)
                                            alert(`Address Copied to Clipboard: ${lwm.address} `)
                                        }}
                                    >
                                        {lwm.address}
                                    </div>
                                </Tooltip>
                            </div>
                        })()
                    }
                </div>
                <div className="connection-status-dot" style={{ backgroundColor: lwm === null ? `#aa0000` : `#00aa00` }} />
                <div className="top-icon">
                    <Tooltip title={(lwm === null) ? 'Not Connected' : 'Connected'} placement="bottom">
                        <WalletIcon
                            fontSize="large"
                        // sx={{ color: (lwm === null) ? `#990000` : `#225522` }}
                        />
                    </Tooltip>
                </div>
                <div className="top-icon">
                    <DisconnectButton />
                </div>
            </div>
        </div>
    </div>
}
