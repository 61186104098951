import AnchorWalletTextLogo from "../FigmaComponets/AnchorWalletTextLogo"
import { TopLine } from "../FigmaComponets/TopLine"

import "../App.css"
import ConnectionStatus from "./ConnectionStatus"

/**
 * @name Top
 * @description The top of the page
 * @author William Doyle 
 */
export default function Top() {
    return <div className="top" >
        <div className="row-space-between" >
            <AnchorWalletTextLogo />
            <div className="row-space-between" >
                <ConnectionStatus />
            </div>
        </div>
        <TopLine />
    </div>
}