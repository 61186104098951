import {createSlice } from '@reduxjs/toolkit'

const selectedTokenSlice = createSlice({
    name: 'selectedTokenSlice',
    initialState: {
        tokenId: null,
    },
    reducers: {
        setSelectedToken: (state:any, action:any) => {
            state.tokenId = action.payload
        }
    }
})

export default selectedTokenSlice
export const { setSelectedToken } = selectedTokenSlice.actions