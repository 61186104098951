import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"

export default function Promotion() {
    const navigate = useNavigate()

    return <div className="feedback-page" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }}>
        {/* <iframe
            title="promotional form"
            src="https://docs.google.com/forms/d/e/1FAIpQLScE_RPZPF49Xu45mkhTwePYoIhwvW3GUQh5l6F44rbzUvgk4A/viewform?embedded=true"
            width="640"
            height="574"
            frameBorder={0}
            marginHeight={0}
            marginWidth={0}>
            Loading…
        </iframe> */}
        <h1>
            Thank you for your interest in our promotion! Unfortunately, it is now over. Follow us on twitter to get notified about future product releases and promotions!
        </h1>
        <h2>
            <a href="https://twitter.com/pauli_group" target="_blank" rel="noopener noreferrer">Twitter</a>
        </h2>
        {
            Array.from({ length: 3 }).map((_, i) => <br key={i} />)
        }
        <Button variant="contained" onClick={() => navigate('/')}>
            back to home
        </Button>
    </div>

}