import { createSlice } from '@reduxjs/toolkit'
import { ChainData } from '../../FigmaComponets/ConnectOrMakeWallet'

type InitialState = {
    collectionName: string,
    collectionAddress: string,
    chain: ChainData,
}

const initialState = {
    collectionName: '',
    collectionAddress: '',
    chain: {} as ChainData,
}

const sendNftDetailsSlice = createSlice({
    name: 'sendNftDetailsSlice',
    initialState: initialState,
    reducers: {
        setNftDetails: (state: InitialState, action: any) => {
            const { collectionName, collectionAddress,  chain } = action.payload
            state.collectionName = collectionName
            state.collectionAddress = collectionAddress
            state.chain = chain
        },
    }
})

export default sendNftDetailsSlice
export const { setNftDetails } = sendNftDetailsSlice.actions
