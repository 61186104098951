/**
 * @name isEnsName
 * @description Checks if a string has the approximate format of an ENS name
 * @date January 26th 2023
 * @author William Doyle 
 */
export default function isEnsName(name: string) : boolean {
    if (name.length < 7) 
        return false

    return name.endsWith('.eth')
}
