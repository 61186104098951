import { createSlice } from '@reduxjs/toolkit'

const walletFileSlice = createSlice({
    name: 'walletFileSlice',
    initialState: {
        walletFile : '',
    },
    reducers: {
        setWalletFile: (state:any, action:any) => {
            if (typeof action.payload != 'string') 
                throw new Error('Expected payload to be a string')
            state.walletFile = action.payload
        }
    }
})
export default walletFileSlice
export const { setWalletFile } = walletFileSlice.actions