import { Button, Step, StepLabel, Stepper, Tooltip } from "@mui/material"
import { ethers } from "ethers"
import { supportedBlockchains } from "lamportwalletmanager/src"
import { WaiterCallback } from "lamportwalletmanager/src/LamportWalletManager"
import React from "react"
import { useSelector } from "react-redux"
import { TopLine } from "../../FigmaComponets/TopLine"
import downloadToFile from "../../functions/downloadToFile"
import useLamportWalletManager from "../../hooks/useLamportWalletManager"
import MetamaskHelper from "../../MetamaskHelper"
import { useAppDispatch } from "../../redux/hooks"
import { setActiveStep } from "../../redux/slices/creationProgressSlice"
import { setWalletFile } from "../../redux/slices/walletFileSlice"
import { AppDispatch } from "../../redux/store"
import AddressInput from "../AddressInput"
import EnsNameTag from "../EnsNameTag"
import TransactionInFlightIndicator from "../TransactionInFlightIndicator"
import BaseOverlay from "./BaseOverlay"

type SendNftOverlayProps = {
    setShowOverlay: React.Dispatch<React.SetStateAction<boolean>>,
    showOverlay: boolean,
    transactionInFlight: boolean,
    setTransactionInFlight: React.Dispatch<React.SetStateAction<boolean>>,
}

const sendNftOverlaySteps = [
    {
        label: 'Enter Recipient Address',
    },
    {
        label: 'Initiate Transaction And Save Wallet File',
    },
    {
        label: 'Await Transaction Confirmation',
    },
    {
        label: 'Continue',
    },
]

export { sendNftOverlaySteps }

type BodyProps = {

}

export default function SendNftOverlay(props: SendNftOverlayProps) {

    const Body = (bodyProps: BodyProps) => {
        const [recipientAddress, setRecipientAddress] = React.useState<string>('')
        const selectedToken = useSelector((state: any) => state.selectedToken.tokenId)
        const activeStep = useSelector((state: any) => state.creationProgress.activeStep)
        const steps = useSelector((state: any) => state.creationProgress.steps)
        const lwm = useLamportWalletManager()

        const dispatch: AppDispatch = useAppDispatch()
        const DETAILS = useSelector((state: any) => state.sendNftDetails)


        /**
         * @name handleSend
         * @description Function to handle sending NFTs
         * @author William Doyle
         * @date January 31st 2023
         */
        const handleSend = React.useCallback(async () => {
            if (lwm === null)
                throw new Error('LamportWalletManager is null')

            lwm.setGasPayer(await MetamaskHelper.connect(DETAILS.chain))

            const waiter: WaiterCallback = await (async () => {
                const rvalue = await lwm.transferNft(
                    DETAILS.collectionAddress,
                    selectedToken,
                    recipientAddress,
                )
                props.setTransactionInFlight(true)
                dispatch(setActiveStep(2))

                return rvalue
            })()

            {
                // // update redux
                dispatch(setWalletFile(lwm.toJSON()))

                const chainname = supportedBlockchains.find((chain) => chain.chainid === lwm.state.chainId)?.name
                if (chainname === undefined)
                    throw new Error('Chainname is undefined')

                const fname = `ANCHOR_${chainname}_${lwm.state.walletAddress}.json`

                // save temp file
                const body: string = lwm.toJSON()
                downloadToFile(body, fname, 'application/json')
            }
            const receipt: ethers.providers.TransactionReceipt = await waiter()



            // // AFTER TRANSACTION SETTLES
            props.setTransactionInFlight(false)
            dispatch(setWalletFile(lwm.toJSON())) // update redux .. again with same data... this is a hack to force a run of the useEffect hook used in usePKH... this makes the state aware of the current values on the smart contract
            // dispatch(setActiveStep(2))
            dispatch(setActiveStep(3))
        }, [DETAILS.chain, DETAILS.collectionAddress, dispatch, lwm, recipientAddress, selectedToken])

        function handleCancel() {
            props.setShowOverlay(false)
        }



        return <div>
            <h3>Send {DETAILS.collectionName} # {selectedToken}</h3>
            {/* <Stepper
                activeStep={activeStep}
                // steps={steps}
                steps={sendNftOverlaySteps}
                styleConfig={StepperStyleConfig}
            /> */}

            <Stepper alternativeLabel activeStep={activeStep} >
                {sendNftOverlaySteps.map((step: { label: string }) => (
                    <Step key={step.label}>
                        <StepLabel >{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <div className="column">
                {
                    (() => {
                        if (steps[activeStep].label === 'Enter Recipient Address')
                            return <>
                                <AddressInput id="recipient-address-input" setAddress={setRecipientAddress} />
                                <br />
                                <div className="labeled-nametag">
                                    <label htmlFor="recipient-ens-name-tag">Recipient</label>
                                    <span className="wizard-output-item-value">
                                        <EnsNameTag id="recipient-ens-name-tag" address={recipientAddress} />
                                    </span>
                                </div>
                                <br />
                                <Button variant="contained" onClick={() => dispatch(setActiveStep(1))}>Next</Button>
                            </>
                    })()
                }


                {
                    // if "Initiate Transaction And Save Wallet" --> show text and button
                    (() => {
                        // if ((steps[activeStep].label === 'Initiate Transaction And Save Wallet File') && (props.transactionInFlight === false))
                        if ((steps[activeStep].label === 'Initiate Transaction And Save Wallet File') && (props.transactionInFlight === false))
                            return <>
                                <div className="labeled-nametag">
                                    <label htmlFor="recipient-ens-name-tag">Recipient</label>
                                    <span className="wizard-output-item-value">
                                        <EnsNameTag id="recipient-ens-name-tag" address={recipientAddress} />
                                    </span>
                                </div>
                                <div className="text-restriction">
                                    You will be prompted to save a file. It is critical that you save this file in a safe place. This file contains your post quantum private key. Without it you will not be able to access your funds.
                                </div>
                                <Tooltip title={`Send ${DETAILS.collectionName} # ${selectedToken} to ${recipientAddress}`}>
                                    <Button variant="contained" onClick={handleSend}>
                                        Save File And Send
                                    </Button>
                                </Tooltip>
                            </>
                    })()
                }

                <br />


                {
                    // if "Initiate Transaction And Save Wallet" --> show spinner
                    (() => {
                        // if (steps[activeStep].label === 'Initiate Transaction And Save Wallet File' )
                        if (props.transactionInFlight === true)
                            return <TransactionInFlightIndicator txid={lwm?.topTxHash ?? null} />
                    })()
                }

                {
                    (() => {
                        // if continue
                        if (steps[activeStep].label === 'Continue')
                            return <div className="simple-hoz-center">
                                <Button variant="contained" onClick={handleCancel}>
                                    Close
                                </Button>
                            </div>
                    })()
                }
                < br />
                {/* <TopLine />
                <br />
                <div className="simple-hoz-center">
                    <Button variant="contained" onClick={handleCancel}>
                        Cancel
                    </Button>
                </div> */}
            </div>
        </div >
    }

    const AccessBody = () => <Body />

    return <BaseOverlay
        setShowOverlay={props.setShowOverlay}
        showOverlay={props.showOverlay}
        Body={AccessBody}
    >
    </BaseOverlay>
}