import React from 'react';
import AnchorImage from '../assets/Anchor1.svg'
import LogEvent from '../functions/AnalyticsReporter';

export type FileUploaderProps = {
  handleFile: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
};

/**
 * @source https://medium.com/web-dev-survey-from-kyoto/how-to-customize-the-file-upload-button-in-react-b3866a5973d8
 * @date Retrieved November 25th 2022
 * @retreivedBy William Doyle
 * @adaptedBy William Doyle 
 */
const FileUploader = (props: FileUploaderProps) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef<any>(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event: any) => {
    if (hiddenFileInput?.current === null) {
      return console.log('hiddenFileInput is null');
    }


    hiddenFileInput.current?.click();
    LogEvent("Clicked Connect Wallet Option")
  };  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleFile(event);
  }; return (
    <>
      <div className='file-upload-button-parent'>
        <button className='file-upload-button' onClick={handleClick}>
          {/* Upload a file */}
          <img style={{
            width: '50px',
            height: '50px'
          }} src={AnchorImage} alt="a line art icon of an anchor" />

          <div className="file-upload-label">
            Connect Wallet
          </div>
        </button>
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </>
  );
}
export default FileUploader;
