// an input that takes an ether address or an ens name and returns the address

import React from "react"

import { ethers } from "ethers"
import isEnsName from "../functions/isEnsName"
import Constants from "../Constants"

type AddressInputProps = {
    setAddress: any,
    id: string,
}
/**
 * @name AddressInput
 * @description handle input of ether address or ens name
 * @author William Doyle
 * @date January 26th 2023
 */
export default function AddressInput(props: AddressInputProps) {
    const [addressAsGiven, setAddressAsGiven] = React.useState<string>('')


    function handleRecipientInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const input = e.target.value
        setAddressAsGiven(input)

        // if is valid address --> set recipient address
        if (ethers.utils.isAddress(input)) {
            props.setAddress(input)
            return
        }

        // if is valid ens name --> set recipient address
        if (isEnsName(input)) {
            const mainnetProvider = new ethers.providers.JsonRpcProvider(Constants.mainnet_public_rpc);
            (async () => {
                const resolver = await mainnetProvider.getResolver(input)
                if (resolver === null)
                    return
                const address = await resolver.getAddress()
                if (address === null)
                    return
                props.setAddress(address)
            })()
        }

    }

    return <>
        <input id={props.id} type="text" placeholder="Recipient Address" value={addressAsGiven} onChange={handleRecipientInputChange} />
    </>

}