import React from "react"
import { Avatar, Chip, Tooltip } from "@mui/material"
import { ethers } from "ethers"
import Constants from "../Constants"
import { useAppDispatch } from "../redux/hooks"
import { AppDispatch } from "../redux/store"
import { setEnsNameTag } from "../redux/slices/ensNameTagSlice"
import { useSelector } from "react-redux"

type EnsNameTagProps = {
    address: string,
    id: string,
}

export default function EnsNameTag(props: EnsNameTagProps) {
    const [ensName, setEnsName] = React.useState<string | null>(null);
    const [ensAvatar, setEnsAvatar] = React.useState<string | null>(null);
    const mainnetProvider = React.useMemo(() => new ethers.providers.JsonRpcProvider(Constants.mainnet_public_rpc), []);

    const dispatch: AppDispatch = useAppDispatch();
    const ens_reduxLookup = useSelector((state: any) => state.ensNameTag.lookupTable);


    React.useEffect(() => {
        // 1. check redux for name and avatar
        const lookupResult = ens_reduxLookup[props.address];

        // 2. if have name and avatar, set local state and return
        if (lookupResult !== undefined) {
            setEnsName(lookupResult.name);
            setEnsAvatar(lookupResult.avatar);
            return;
        }

        // 3. if don't have name and avatar, lookup name and avatar + set redux state
        (async () => {
            try {
                const name = await mainnetProvider.lookupAddress(props.address);
                if (name === null)
                    return;
                const resolver = await mainnetProvider.getResolver(name);
                if (resolver === null)
                    return;
                const avatar = await resolver.getText("avatar");
                setEnsName(name);
                setEnsAvatar(avatar);
                dispatch(setEnsNameTag({ address: props.address, name: name, avatar: avatar }));
            } catch (e) {
                setEnsName(props.address);
                dispatch(setEnsNameTag({ address: props.address, name: props.address, avatar: null }));
            }
        })();
    }, [props.address, mainnetProvider, dispatch, ens_reduxLookup]);

    return <span id={props.id}>
        <Tooltip title={props.address}>
            <Chip avatar={
                (() => {
                    if (ensAvatar === null)
                        return <Avatar >
                            {
                                (() => {
                                    if (ensName === null)
                                        return props.address[2]
                                    return ensName[0]
                                })()
                            }
                        </Avatar>
                    return <Avatar src={ensAvatar} />
                })()
            }
                label={ensName ?? props.address}
                variant="outlined" />
        </Tooltip>
    </span>
}
