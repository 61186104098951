import { ethers } from "ethers"
import React from "react"
import MetamaskHelper from "../MetamaskHelper"
import { supportedBlockchains } from "lamportwalletmanager/src"
import { ChainData } from "../FigmaComponets/ConnectOrMakeWallet"
import { Tooltip } from "@mui/material"
import LogEvent from "../functions/AnalyticsReporter"

const erc20s = [
    '0x773a6E8Dde69EB57167Bc5fA0C57e182C693ACEd',
    '0x7566d3348A6025b27C0d50CfAE2109f997De61f1',
    '0x38A5d221728324923F46e7cA491b7998FAa6528a',
    '0xEf36d789291C9Eb026876BC8EEB15D202Feb2931',
    '0x8C68e0c4F41766E91CD08dd5953c4306f3A6F1aD',
    '0x3fA92093AF62DaBf0cC0F51C85E565A120C3D954',
    '0xFf2eC325D07C9a5c186939A007359d6e0065E905',
]

const erc1155s = [
    {
        address: '0xA3B5215f02F015D17f9a931525691073EeaA17B8',
        tokenIds: [
            '0',
            '1',
            '2',
            '3',
            '4',
        ]
    },
    {
        address: '0x2de1bcd5ef1fb15a6a455388384b16ca5153f254',
        tokenIds: [
            '0',
            '1',
            '123',
            '314159265358979323'
        ]
    },
]

const normalMint = async (erc721: any, recipient: string, beforeHook: () => void) => {
    beforeHook()
    const signer = await MetamaskHelper.connect(mumbai as ChainData)
    const contract = new ethers.Contract(erc721.address, ['function awardItem(address to, string uri)'], signer)
    const tx = await contract.awardItem(recipient, "no uri")
    console.log(tx)
    const receipt = await tx.wait()
    console.log(receipt)
}

const dispense = async (dispenser: string, _: any, recipient: string, beforeHook: () => void) => {
    beforeHook()
    const signer = await MetamaskHelper.connect(mumbai as ChainData)
    const contract = new ethers.Contract(dispenser, ['function claim(address to)'], signer)
    const tx = await contract.claim(recipient)
    console.log(tx)
    const receipt = await tx.wait()
    console.log(receipt)
}

const erc721s = [
    {
        address: "0x4b874877635Fbd8dD181cd0A59ca7C2Ae1AfbA6D",
        func: normalMint,
        label: 'Standard ERC721 With Enumeration. No metadata.',
    },
    {
        address: "0xa525940a93f7b4C8F70238B05F59eF0989eb30ED",
        func: normalMint,
        label: 'Standard ERC721 Without Enumeration. No metadata.',

    },
    {
        address: "0x1D3be216Ab46B3F9FB42911d4Ee8Dda8821a3E61",
        func: dispense.bind(null, '0x846e36127Ae3b2C78c2F3DA22CF47a29FdbD0371'),
        label: `These tokens are preminted by William Doyle. It costs me about 3 cents to mint one. I mint them into a dispenser contract. This button will allow you to claim one if there are any owned by the dispenser. Otherwise contact William and request more. `
    }
]


const mumbai = supportedBlockchains.find((blockchain) => blockchain.chainid === '80001')

export default function TestnetAssets() {
    const [recipient, setRecipient] = React.useState("")
    const [isAddress, setIsAddress] = React.useState(false)
    const [selectedAddress, setSelectedAddress] = React.useState('')
    const [selectedTokenType, setSelectedTokenType] = React.useState<'NA' | 'erc20' | 'erc1155' | 'erc721'>('NA')
    const [selectedTokenId, setSelectedTokenId] = React.useState('')
    const [dispenserBalance, setDispenserBalance] = React.useState(0)

    React.useEffect(() => {
        LogEvent("Visited Testnet Assets Page")
    }, [])

    React.useEffect(() => {
        LogEvent("Entered Recipient Address On Testnet Assets Page", {
            recipient,
        })
    }, [recipient])

    React.useEffect(() => {
        (async () => {
            const signer = await MetamaskHelper.connect(mumbai as ChainData)
            const dispenser = new ethers.Contract('0x846e36127Ae3b2C78c2F3DA22CF47a29FdbD0371', ['function supply() view returns (uint256)'], signer)
            const balance = await dispenser.supply()
            setDispenserBalance(balance.toNumber())
        })()
    })

    React.useEffect(() => {
        setIsAddress(ethers.utils.isAddress(recipient))
    }, [recipient])

    return <div style={{
        margin: 50,
    }}>

        <h2>
            Initial Setup
        </h2>
        <div style={{
            display: "flex",
            backgroundColor: "lightgray",
            justifyContent: "center",
            alignContent: "center",
        }}>

            <div style={{
                display: "flex",
                flexDirection: "column",
            }}>
                <img
                    src={`https://qr.expo.dev/expo-go?owner=williamfrompauligroup&slug=anchor-wallet-mobile-app&releaseChannel=default&host=exp.host`}
                    alt={"QR code for adding token"}
                    style={{
                        width: "250px",
                        height: "250px",
                    }}
                />
                <button onClick={() => {
                    // paste the URL into the users clipboard
                }}>
                    Copy URL
                </button>
                <a href="https://apps.apple.com/app/apple-store/id982107779" target="_blank" rel="noopener noreferrer">
                    Get Expo Go on the App Store
                </a>

                <a href="https://play.google.com/store/apps/details?id=host.exp.exponent&referrer=www" target="_blank" rel="noopener noreferrer">
                    Get Expo Go on the Play Store
                </a>
            </div>
            <div style={{
                whiteSpace: "pre-wrap",
            }}>
                {`
                            1. Install Expo Go on your phone
                            2. Scan this QR code, or press "Copy URL" and paste it into Expo Go 
                            3. When the app launches, press "Get Started"
                            4. Press "Create new Gas Tank"
                            5. Click to copy your gas tank address to your clipboard
                            6. Press "Go To Faucet"
                            7. Paste your gas tank address into the input field and press "Send Me MATIC"
                            8. Return to the gas tank screen and wait for the funds to appear
                            9. Once the funds appear, press "Proceed"
                           10. Press "Lets Go"
                           11. Wait. After the transaction settles, you will be taken to your new wallet
                        `}
            </div>
        </div>

        <h2>
            Adding Tokens
        </h2>
        <div style={{
            display: "flex",
            backgroundColor: "lightgray",
            justifyContent: "space-around",
            alignContent: "center",
        }}>

            <div style={{
                whiteSpace: "pre-wrap",
            }}>
                {`
                          1. Enter the address of your LAMB account (not your gas tank) into the text box below.

                          You can find this address by navigating to the screen depicted in Figure 1 and pressing "Copy Address"
                        
                          2. Click one of the below buttons to mint that token into your LAMB account.
                          3. Instructions specific to the token type will appear. Follow those instructions to make the token visable in your wallet.
                        `}
            </div>

            <div style={{
                whiteSpace: "pre-wrap",
            }}>
                <label>
                    <img src="https://firebasestorage.googleapis.com/v0/b/wallet-wi.appspot.com/o/lambAddress.png?alt=media"
                        alt="screenshot of lamb address"
                        style={{
                            width: "120px",
                            height: "250px",
                        }}
                    />
                    Figure 1
                </label>
            </div>
        </div>
        <h1>
            Testnet Assets
        </h1>
        <h3>
            Here you can get tokens on polygon mumbai for testing
        </h3>
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
        }}>
            <label>
                Enter Recipient Address
                <input
                    type="text"
                    value={recipient}
                    placeholder="recipient address"
                    onChange={(e) => {
                        setRecipient(e.target.value)
                    }}
                />
            </label>

        </div>
        <h2>
            MATIC
        </h2>
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
            }}
        >
            <div>
                Please use <a href="https://mumbaifaucet.com/" target="_blank" rel="noopener noreferrer">this site</a> to acquire Testnet Matic
            </div>
        </div>

        <h2>
            ERC20s
        </h2>
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
            }}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "lightgray",
            }}>
                {
                    erc20s.map((erc20) => {
                        return <button
                            key={erc20}
                            disabled={!isAddress}
                            onClick={async () => {
                                // call erc20.mint(recipient)
                                setSelectedAddress(erc20)
                                setSelectedTokenType('erc20')
                                const signer = await MetamaskHelper.connect(mumbai as ChainData)
                                const contract = new ethers.Contract(erc20, ['function mint(address to)'], signer)
                                const tx = await contract.mint(recipient)
                                console.log(tx)
                                const receipt = await tx.wait()
                                console.log(receipt)
                            }}
                        >
                            {erc20}
                        </button>
                    })
                }
            </div>

            <div style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "lightgray",
            }}>
                {
                    erc20s.map((erc20) => {
                        return <a href={`https://mumbai.polygonscan.com/address/${erc20}`} target="_blank" rel="noopener noreferrer">
                            View {erc20}
                        </a>
                    })
                }
            </div>
            {

                (selectedTokenType === 'erc20') && <div style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "lightgray",
                    alignItems: "center",
                }}>
                    <img
                        src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${selectedAddress}&choe=UTF-8`}
                        alt={"QR code for adding token"}
                        style={{
                            width: "100px",
                            height: "100px",
                        }}
                    />
                    <h4>
                        Instructions
                    </h4>
                    <div style={{
                        whiteSpace: "pre-wrap",
                    }}>
                        {`
                            1. Open "Account Settings" in the app
                            2. Locate the "Add Token" button and press it, a popup will appear
                            3. Ensure "ERC20" is selected
                            4. Use the QR code scanner to scan this QR code
                            5. Press "Add" button to finish
                        `}
                    </div>
                </div>
            }
        </div>

        <h2>
            ERC1155s
        </h2>
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
        }}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "lightgray",
            }}>
                {
                    erc1155s.map((erc1155) => {
                        return erc1155.tokenIds.map((tokenId) => {
                            return <button
                                key={tokenId}
                                disabled={!isAddress}
                                onClick={async () => {
                                    setSelectedAddress(erc1155.address)
                                    setSelectedTokenType('erc1155')
                                    setSelectedTokenId(tokenId)
                                    // call mint
                                    const signer = await MetamaskHelper.connect(mumbai as ChainData)
                                    const contract = new ethers.Contract(erc1155.address, ['function mint(uint256 tokenId, address to, uint256 amount, bytes data)'], signer)
                                    const tx = await contract.mint(tokenId, recipient, 1, "0x00")
                                    console.log(tx)
                                    const receipt = await tx.wait()
                                    console.log(receipt)
                                }}
                            >
                                {tokenId} @ {erc1155.address}
                            </button>
                        })
                    })
                }
            </div>

            <div style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "lightgray",
            }}>
                {
                    erc1155s.map((erc1155) => {
                        const address = erc1155.address
                        return erc1155.tokenIds.map((tokenId) => {
                            return <a href={`https://mumbai.polygonscan.com/token/${address}?a=${tokenId}`} target="_blank" rel="noopener noreferrer">
                                View {address} {tokenId}
                            </a>
                        })
                    })
                }
            </div>

            {

                (selectedTokenType === 'erc1155') && <div style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "lightgray",
                    alignItems: "center",
                }}>
                    <img
                        src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${selectedAddress}&choe=UTF-8`}
                        alt={"QR code for adding token"}
                        style={{
                            width: "100px",
                            height: "100px",
                        }}
                    />
                    <h4>
                        Instructions
                    </h4>
                    <div style={{
                        whiteSpace: "pre-wrap",
                    }}>
                        {`
                            1. Open "Account Settings" in the app
                            2. Locate the "Add Token" button and press it, a popup will appear
                            3. Ensure "ERC1155" is selected
                            4. Use the QR code scanner to scan this QR code
                            5. Enter "${selectedTokenId}" in the "Token ID" field
                            6. Press "Add" button to finish
                        `}
                    </div>
                </div>
            }

        </div>

        <h2>
            ERC721s
        </h2>
        {
            `Dispenser Balance: ${dispenserBalance}`
        }

        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
        }}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "lightgray",
            }}>
                {
                    erc721s.map((erc721) => {
                        return <Tooltip title={erc721.label}>
                            <button
                                key={`erc721-${erc721.address}`}
                                disabled={!isAddress}
                                onClick={erc721.func.bind(null, erc721, recipient, () => {
                                    setSelectedAddress(erc721.address)
                                    setSelectedTokenType('erc721')
                                })}
                            >
                                {erc721.address}
                            </button>
                        </Tooltip>
                    })
                }
            </div>

            <div style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "lightgray",
            }}>
                {
                    erc721s.map((erc721) => {
                        return <a href={`https://mumbai.polygonscan.com/token/${erc721.address}`} target="_blank" rel="noopener noreferrer">
                            View {erc721.address}
                        </a>
                    })
                }
            </div>


            {

                (selectedTokenType === 'erc721') && <div style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "lightgray",
                    alignItems: "center",
                }}>
                    <img
                        src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${selectedAddress}&choe=UTF-8`}
                        alt={"QR code for adding token"}
                        style={{
                            width: "100px",
                            height: "100px",
                        }}
                    />
                    <h4>
                        Instructions
                    </h4>
                    <div style={{
                        whiteSpace: "pre-wrap",
                    }}>
                        {`
                            1. Open "Account Settings" in the app
                            2. Locate the "Add Token" button and press it, a popup will appear
                            3. Ensure "ERC721" is selected
                            4. Use the QR code scanner to scan this QR code
                            5. Wait a moment, if the token id field disappears, this is an Enumeable ERC721 and you 
                               don't need to add the token id manually, skip to the last step
                            6. If the token id field is still visable, you will need to locate the token id using the 
                               blockchain explorer and enter it manually. If you've just minted the token, you can look
                               at your most recent transaction to find the token id
                            7. Press "Add" button to finish
                        `}
                    </div>
                </div>
            }
        </div>

    </div>
}