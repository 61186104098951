import { createSlice } from '@reduxjs/toolkit'

const ensNameTagSlice = createSlice({
    name: 'ensNameTagSlice',
    initialState: {
        lookupTable: {},
    },
    reducers: {
        setEnsNameTag: (state:any, action:any) => {
            if (typeof action.payload != 'object')
                throw new Error('Expected payload to be an object')
            
            const { address, name, avatar } = action.payload

            state.lookupTable[address] = {
                name,
                avatar
            }
        }
    }
})

export default ensNameTagSlice
export const { setEnsNameTag } = ensNameTagSlice.actions