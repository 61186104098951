import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? "",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? "",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? "",
    appId: process.env.REACT_APP_FIREBASE_APP_ID ?? "",
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? "",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export type EventTitle = "Visited Site" | "Created New Wallet" | "Clicked New Wallet Option" | "Clicked Connect Wallet Option" | "Visited Testnet Assets Page" | "Entered Recipient Address On Testnet Assets Page"

export default function LogEvent(title : EventTitle, params : any = {}) {
    logEvent(analytics, title, params)
}