import { Button, Step, StepLabel, Stepper, Tooltip } from "@mui/material"
import { ethers } from "ethers"
import { supportedBlockchains } from "lamportwalletmanager/src"
import { WaiterCallback } from "lamportwalletmanager/src/LamportWalletManager"
import React from "react"
import { useNavigate } from "react-router"
import { ChainData } from "../../FigmaComponets/ConnectOrMakeWallet"
import downloadToFile from "../../functions/downloadToFile"
import useLamportWalletManager from "../../hooks/useLamportWalletManager"
import useLwmErc20Info, { CurrencyInfo } from "../../hooks/useLwmErc20Info"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import MetamaskHelper from "../../MetamaskHelper"
import { useAppDispatch } from "../../redux/hooks"
import { setWalletFile } from "../../redux/slices/walletFileSlice"
import { AppDispatch } from "../../redux/store"
import TransactionInFlightIndicator from "../TransactionInFlightIndicator"
import { setActiveStep, setSteps } from "../../redux/slices/creationProgressSlice";
import { useSelector } from "react-redux"
import EnsNameTag from "../EnsNameTag"
import Constants from "../../Constants"
import isEnsName from "../../functions/isEnsName"
import AddressInput from "../AddressInput"

type SendOverlayProps = {
    setShowOverlay: any,
    showOverlay: boolean,
    tokenNameOuter: string,
    chain: ChainData,
    setTransactionInFlight: any,
    transactionInFlight: boolean,
}

export const sendOverlaySteps = [
    {
        label: 'Enter Recipient Address',
    },
    {
        label: 'Enter Amount',
    },
    {
        label: 'Initiate Transaction And Save Wallet File',
    },
    {
        label: 'Await Transaction Confirmation',
    },
]



/**
    * @name SendOverlay
    * @description This is the overlay that appears when the user clicks the send button
    * @author William Doyle
    */
export function SendOverlay(props: SendOverlayProps) {
    const wrapperRef = React.useRef<HTMLDivElement | null>(null)
    useOnClickOutside(wrapperRef, () => props.setShowOverlay(false))

    const dispatch: AppDispatch = useAppDispatch()
    const erc20CurrencyInfo = useLwmErc20Info()
    const lwm = useLamportWalletManager()
    const navigate = useNavigate()
    const [recipientAddress, setRecipientAddress] = React.useState<string>('')
    const [amount, setAmount] = React.useState<string>('')
    const [tokenAddress, setTokenAddress] = React.useState<string>(ethers.constants.AddressZero)
    const [tokenName, setTokenName] = React.useState<string>('Native Token')
    const [decimals, setDecimals] = React.useState<number>(18)

    const activeStep = useSelector((state: any) => state.creationProgress.activeStep)
    const steps = useSelector((state: any) => state.creationProgress.steps)

    // React.useEffect(() => {
    //     dispatch(setSteps(sendOverlaySteps))
    //     console.log('setting steps')
    // }, [props.showOverlay, dispatch])

    // EFFECT: update decimals when token address changes
    React.useEffect(() => {
        if (tokenAddress === ethers.constants.AddressZero) {
            setDecimals(18)
            return
        }
        (async () => {
            const decimals: string | void | undefined = await lwm?.getDecimalsOfERC20(tokenAddress)
                .catch(() => {
                    console.log(`Error while trying to get decimals of ERC-20 token with address ${tokenAddress}`)
                })

            console.log(`decimals: ${decimals}`)

            if (typeof decimals !== 'string') {
                // navigate to error page
                console.log(`decimals is not a string. It is ${typeof decimals}.`)
                return
            }

            setDecimals(parseInt(decimals))
        })()
    }, [tokenAddress, navigate, lwm])

    // EFFECT: update token address when token name changes
    React.useEffect(() => {
        if (tokenName === 'Native Token') {
            setTokenAddress(ethers.constants.AddressZero)
            return
        }

        const currencyInfo: CurrencyInfo | undefined = erc20CurrencyInfo.find((currency) => currency.name === tokenName)
        if (currencyInfo === undefined)
            throw new Error('CurrencyInfo is undefined')

        setTokenAddress(currencyInfo.address)
    }, [tokenName, erc20CurrencyInfo])

    // EFFECT: update token name when outer token name changes
    React.useEffect(() => {
        setTokenName(props.tokenNameOuter)
    }, [props.tokenNameOuter])

    if (props.showOverlay === false)
        return null

    /**
     * @name handleSend
     * @description
     * @author William Doyle
     * @date November 22nd 2022
     */
    async function handleSend() {
        if (lwm === null)
            throw new Error('LamportWalletManager is null')

        lwm.setGasPayer(await MetamaskHelper.connect(props.chain))
        const formatedAmount: ethers.BigNumber = ethers.utils.parseUnits(amount, decimals)

        console.log(`sending ${formatedAmount} to ${recipientAddress}`)
        props.setTransactionInFlight(true)

        const waiter: WaiterCallback = await (async () => {
            if (tokenName === 'Native Token') {
                console.log('sending the native token')
                return await lwm.call_sendEther(recipientAddress, formatedAmount)
                // return await lwm.call_sendEtherWithManualGas (recipientAddress, formatedAmount, '1000000', '100000') // SEPOLIA
            }

            console.log(`sending ${amount} of ${tokenName} to ${recipientAddress}`)
            return await lwm.transferErc20(tokenAddress, recipientAddress, formatedAmount.toString())
        })()

        // update redux
        dispatch(setWalletFile(lwm.toJSON()))

        const chainname = supportedBlockchains.find((chain) => chain.chainid === lwm.state.chainId)?.name
        if (chainname === undefined)
            throw new Error('Chainname is undefined')

        const fname = `ANCHOR_${chainname}_${lwm.state.walletAddress}.json`

        {
            // save temp file
            const body: string = lwm.toJSON()
            downloadToFile(body, fname, 'application/json')
        }

        const receipt: ethers.providers.TransactionReceipt = await waiter()
        // AFTER TRANSACTION SETTLES
        dispatch(setWalletFile(lwm.toJSON())) // update redux .. again with same data... this is a hack to force a run of the useEffect hook used in usePKH... this makes the state aware of the current values on the smart contract
        console.log(receipt)
        props.setTransactionInFlight(false)
        dispatch(setActiveStep(3))
        // props.setShowOverlay(false)
    }

    const displayTokenName = (s: string) => {
        if (s === 'Native Token')
            return props.chain.currencyTicker
        return s
    }

    const RecipiantFeedback = () => <div>
        <label htmlFor="recipient-ens-name-tag">Recipient</label>
        <span className="wizard-output-item-value">
            <EnsNameTag id="recipient-ens-name-tag" address={recipientAddress} />
        </span>
    </div>

    const NextButton = () => <Button variant="contained" onClick={() => dispatch(setActiveStep(activeStep + 1))}>Next</Button>

    return <div className='shadow'>
        <div ref={wrapperRef} className="overlay">
            <div className="overlay-content">
                {/* <Stepper
                    activeStep={activeStep}
                    steps={steps}
                    styleConfig={StepperStyleConfig}
                /> */}
                <Stepper alternativeLabel activeStep={activeStep} >
                    {sendOverlaySteps.map((step: { label: string }) => (
                        <Step key={step.label}>
                            <StepLabel >{step.label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <h3>Send {displayTokenName(tokenName)}</h3>

                <div className="column">
                    {
                        // if "Enter Recipient Address" --> show input
                        (() => {
                            if (steps[activeStep].label === 'Enter Recipient Address')
                                return <>
                                    <AddressInput id="recipient-address-input" setAddress={setRecipientAddress} />
                                    <br />

                                    <RecipiantFeedback />
                                    <br />
                                </>
                        })()
                    }
                    {
                        // if not "Enter Recipient Address" --> show feedback
                        (() => {
                            if (steps[activeStep].label !== 'Enter Recipient Address')
                                return <RecipiantFeedback />
                        })()
                    }
                    {
                        // if "Enter Amount" --> show input
                        (() => {
                            if (steps[activeStep].label === 'Enter Amount')
                                return <>
                                    <br />
                                    <input type="number" placeholder="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                    <br />
                                </>
                        })()
                    }

                    {
                        // if "Await Transaction Confirmation" or "Initiate Transaction And Save Wallet File" --> amount feedback
                        (() => {
                            if (['Await Transaction Confirmation', 'Initiate Transaction And Save Wallet File'].includes(steps[activeStep].label))
                                return <div>
                                    <br />
                                    <div>
                                        <label htmlFor="amount-to-send-output">To Send</label>
                                        <span id="amount-to-send-output" className="wizard-output-item-value" style={{
                                            borderStyle: 'solid',
                                            borderWidth: '1px',
                                            padding: '5px',
                                            borderRadius: '10px',
                                            borderColor: 'gray',
                                        }}>
                                            {amount + ' ' + displayTokenName(tokenName)}
                                        </span>
                                    </div>
                                </div>
                        })()

                    }

                    {
                        // if "Initiate Transaction And Save Wallet" --> show spinner
                        (() => {
                            // if (steps[activeStep].label === 'Initiate Transaction And Save Wallet File' )
                            if (props.transactionInFlight === true)
                                return <TransactionInFlightIndicator txid={lwm?.topTxHash ?? null} />
                        })()
                    }

                    {
                        // if "Initiate Transaction And Save Wallet" --> show text and button
                        (() => {
                            if ((steps[activeStep].label === 'Initiate Transaction And Save Wallet File') && (props.transactionInFlight === false))
                                return <>
                                    <div className="text-restriction">
                                        You will be prompted to save a file. It is critical that you save this file in a safe place. This file contains your post quantum private key. Without it you will not be able to access your funds.
                                    </div>
                                    <Tooltip title={`Send ${amount} ${displayTokenName(tokenName)} to ${recipientAddress}`}>
                                        <Button variant="contained" onClick={handleSend}>
                                            Save File And Send
                                        </Button>
                                    </Tooltip>
                                </>
                        })()
                    }

                    {
                        (() => {
                            // if "Await Transaction Confirmation" --> show finished screen
                            if (steps[activeStep].label === 'Await Transaction Confirmation') {
                                return <div className="simple-hoz-center">
                                    <h3>
                                        🎉🎉Finished🎉🎉
                                    </h3>
                                    <br />
                                    <div>
                                        <Button variant="contained" onClick={() => {
                                            // set to step 1
                                            dispatch(setActiveStep(0))

                                            // reset amount
                                            setAmount('')

                                            // reset recipient address
                                            setRecipientAddress('')

                                            // dismiss overlay
                                            props.setShowOverlay(false)
                                        }}>
                                            Dismiss
                                        </Button>
                                    </div>
                                </div>
                            }
                        })()
                    }

                    {
                        // if not "Await Transaction Confirmation" or "Initiate Transaction And Save Wallet File" --> show next button
                        (() => {
                            if (['Await Transaction Confirmation', 'Initiate Transaction And Save Wallet File'].includes(steps[activeStep].label))
                                return null
                            return <NextButton />
                        })()
                    }

                </div>
            </div>
        </div>
    </div>
}
