import React from "react";
import { useAppSelector } from "../redux/hooks";
import LamportWalletManager from "lamportwalletmanager/src/LamportWalletManager"

/**
 *  @name useLamportWalletManager
 *  @description custom hook to get an instance of the LamportWalletManager from data in the redux store
 *  @author William Doyle
 *  @date November 21st 2022
 */
export default function useLamportWalletManager() : LamportWalletManager | null {
    const [lwm, setLwm] = React.useState<LamportWalletManager | null>(null);
    const rawKeyFile = useAppSelector(state => state.walletFile)

    // when redux data changes >>> create a new instance of the LamportWalletManager
    React.useEffect(() => {
        if (rawKeyFile.walletFile === '') {
            setLwm(null)
            return
        }
        const lwm = LamportWalletManager.fromJSON(rawKeyFile.walletFile)
        setLwm(lwm)
    }, [rawKeyFile.walletFile])
    
    return lwm
}
