import { ethers } from "ethers"
import React from "react"
import MetamaskHelper from "../MetamaskHelper"
import supportedBlockchains from "lamportwalletmanager/src/supportedBlockchains.json";
import Example777Json from "../Example777.json"
import ExampleAdvanced1155 from "../ExampleAdvanced1155.json"


export default function ReleventAddresses() {
    const [qrCodeURI, setQrCodeURI] = React.useState<string>('')

    return <div className="relevent-addresses-page">
        <h2>Company Accounts</h2>
        <table border={1}>
            <tr>
                Main:  <a href="https://etherscan.io/enslookup-search?search=pauligroup.eth" target="_blank" rel="noopener noreferrer">pauligroup.eth</a>
            </tr>
            <tr>
                Test: <a href="https://etherscan.io/address/0x94D4Da7bDe814ae3B7a3D25A108391018e1e495E" target="_blank" rel="noopener noreferrer">0x94D4Da7bDe814ae3B7a3D25A108391018e1e495E</a>
            </tr>
        </table>

        <h2>Contracts</h2>
        <h3>Challenges</h3>
        <table border={1}>
            <tr>
                Type 1 challeneges (Polygon): <a href="https://polygonscan.com/address/0x34a86b3b9523d2d19bbf199329983c802b3d4760" target="_blank" rel="noopener noreferrer">type1.challenges.pauligroup.eth</a>
            </tr>
            <tr>
                Type 2 challeneges: <i>NA</i>
            </tr>
        </table>
        <h3>Wallet Factories</h3>
        <table border={1}>
            <tr>
                <td>

                    <div>
                        Anchor Wallet Type 1 Factory - Ethereum: <a href="https://etherscan.io/enslookup-search?search=wallet.anchor.pauligroup.eth" target="_blank" rel="noopener noreferrer">wallet.anchor.pauligroup.eth</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        Anchor Wallet Type 1 Factory - Polygon: <a href="https://polygonscan.com/address/0x6eaE729F136Fa64833Ef40bcECeBfD6442F00659" target="_blank" rel="noopener noreferrer">0x6eaE729F136Fa64833Ef40bcECeBfD6442F00659</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        Anchor Wallet Type 1 Factory - Avalanche: <a href="https://snowtrace.io/address/0x47425b57e18c139343f3fed1c2b840d9e010070b" target="_blank" rel="noopener noreferrer">0x47425b57e18c139343f3fed1c2b840d9e010070b</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>

                    <div>
                        Anchor Wallet Type 1 Factory - Moonriver: <a href="https://moonriver.moonscan.io/address/0xD3987e3774624327901FdD1F062Df9949211991e" target="_blank" rel="noopener noreferrer">0xD3987e3774624327901FdD1F062Df9949211991e</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        Anchor Wallet Type 1 Factory - Binance: <a href="https://bscscan.com/address/0x1c7671740A8d0E18F431557c73c98EA36D8b9bc0" target="_blank" rel="noopener noreferrer">0x1c7671740A8d0E18F431557c73c98EA36D8b9bc0</a>
                    </div>
                </td>
            </tr>

            <tr>
                <td>
                    <div>
                        Anchor Wallet Type 1 Factory - Gnosis: <a href="https://gnosisscan.io/address/0x47425b57e18c139343f3fed1c2b840d9e010070b" target="_blank" rel="noopener noreferrer">0x47425b57e18c139343f3fed1c2b840d9e010070b</a>
                    </div>
                </td>

            </tr>
        </table>

        <h4>
            Anchor Wallet Type 3 Addresses
        </h4>

        <table border={1}>
            <thead>
                <tr>
                    <th>Network</th>
                    <th>Address</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        Ethereum
                    </td>
                    <td>
                        <a href="https://etherscan.io/address/0xBbec2f0bd58EA433BB38ac8ed699DED914087D6f" target="_blank" rel="noopener noreferrer">0xBbec2f0bd58EA433BB38ac8ed699DED914087D6f</a>
                    </td>
                    <td>
                        Factory address of Anchor Wallet Type 3 (the one which uses a chrome extension as the UI)
                    </td>
                </tr>
                <tr>
                    <td>
                        Polygon
                    </td>
                    <td>
                        <a href="https://polygonscan.com/address/0x5d0f537540C96E98077719f81761ed24a428E01B" target="_blank" rel="noopener noreferrer">
                            0x5d0f537540C96E98077719f81761ed24a428E01B
                        </a>
                    </td>
                    <td>
                        Factory address for Anchor Wallet Type 3 on Polygon
                    </td>
                </tr>
                <tr>
                    <td>
                        Binance Smart Chain
                    </td>
                    <td>
                        <a href="https://bscscan.com/address/0xF1A92134Da9fA5f3412781303Cb2BE2681d063fE" target="_blank" rel="noopener noreferrer">
                            0xF1A92134Da9fA5f3412781303Cb2BE2681d063fE
                        </a>

                    </td>
                    <td>
                        Factory address for Anchor Wallet Type 3 on Binance Smart Chain
                    </td>
                </tr>
                <tr>
                    <td>
                        Sepolia (testnet)
                    </td>
                    <td>
                        <a href="https://sepolia.etherscan.io/address/0xd702d1700b29019AafAa5e48a411eFcbD0354c32" target="_blank" rel="noopener noreferrer">
                            0xd702d1700b29019AafAa5e48a411eFcbD0354c32
                        </a>
                    </td>
                    <td>
                        Factory address for Anchor Wallet Type 3 on the sepolia testnet
                    </td>
                </tr>

            </tbody>

        </table>

        <h3>
            Thin Air
        </h3>
        <table border={1}>
            <tr>
                <td>
                    ThinAir - ERC20 Token Contract (Polygon)
                </td>
                <td>
                    <a
                        href="https://polygonscan.com/token/0x77f7A39f5C5E297A0E9dDd5BAcba44b12cc851b1"
                        target="_blank"
                        rel="noopener noreferrer">
                        0x77f7A39f5C5E297A0E9dDd5BAcba44b12cc851b1
                    </a>
                </td>
            </tr>
            <tr>
                <td>
                    Website
                </td>
                <td>
                    <a href="https://thinaircoin.com/" target="_blank" rel="noopener noreferrer">
                        thinaircoin.com
                    </a>
                </td>
            </tr>
        </table>

        <h2>Employees And Associates </h2>
        <table border={1}>
            <tr>
                <td>
                    Pierre-Luc: <a href="https://etherscan.io/enslookup-search?search=pldd.eth" target="_blank" rel="noopener noreferrer">pldd.eth</a>
                </td>
            </tr>
            <tr>
                <td>
                    Pierre-Luc 2: <a href="https://etherscan.io/address/0x80208d00a045c8325e398eeeee348e0477a3a5fb" target="_blank" rel="noopener noreferrer">0x80208D00A045c8325e398EEEeE348e0477a3a5fB</a>
                </td>
            </tr>
            <tr>
                <td>
                    Pierre-Luc Lamport V1 CryptoKitty: <a href="https://etherscan.io/address/0xe1c67b7db6ea02125d4a2d4ec91d382dbf98e3d9" target="_blank" rel="noopener noreferrer">0xE1C67B7Db6eA02125D4a2d4Ec91D382dbF98E3d9</a>
                </td>
            </tr>
            <tr>
                <td>
                    William: <a href="https://etherscan.io/enslookup-search?search=williamdoyle.eth" target="_blank" rel="noopener noreferrer">williamdoyle.eth</a>
                </td>
            </tr>
            <tr>
                <td>
                    Sahak: <a href="https://etherscan.io/enslookup-search?search=sahak.eth" target="_blank" rel="noopener noreferrer">sahak.eth</a>
                </td>
            </tr>

        </table >

        {/* <h2>Testnet Stuff</h2>
        <h3>
            Use the blockchain explorer <a href="https://mumbai.polygonscan.com/" target="_blank" rel="noopener noreferrer">here</a>. Get testnet MATIC from <a href="https://mumbaifaucet.com/" target="_blank" rel="noopener noreferrer">here</a>.
            You may want to consider signing up for a free Infura and Alchemy account to get your own RPC endpoint. This should improve your experience. You can add these endpoints to the app in settings. You can get an infura account <a href="https://www.infura.io/" target="_blank" rel="noopener noreferrer">here</a> and an Alchemy account <a href="https://www.alchemy.com/" target="_blank" rel="noopener noreferrer">here</a>.
        </h3>
        <img src={qrCodeURI} alt="QR code resolving to selected contract address" />
        <table border={1}>
            <thead>
                <tr>
                    <th>Network</th>
                    <th>Address</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Mumbai</td>
                    <td>0xb2c4d0111Ab40bdB414daeE2e3F53c8e2f7254Ec</td>
                    <td>Fake DAI</td>
                </tr>
                <tr>
                    <td>Mumbai</td>
                    <td>0x4972838dDEED2accEf533BFb45e8121c5Fa7c864</td>
                    <td>Testnet NFT</td>
                </tr>
                <tr>
                    <td>Mumbai</td>
                    <td>0x34992de82775D3ea8d0FCEecf0D0aA734eed90Fe</td>
                    <td>Testnet NFT</td>
                </tr>

                <tr>
                    <td>Mumbai</td>
                    <td>0x72Bd1982693f294f7aaa466d024e3c1B370355BF</td>
                    <td>Testnet NFT</td>
                </tr>

                <tr>
                    <td>Mumbai</td>
                    <td>0xFE590114a412427d6232659Bc7448Daec8f8eB40</td>
                    <td>Testnet NFT (DAI Bills)</td>
                </tr>




                <tr>
                    <td>Mumbai</td>
                    <td>
                        0xb2c4d0111Ab40bdB414daeE2e3F53c8e2f7254Ec
                    </td>
                    <td>Testnet ERC20 -- fake dai</td>
                </tr>
                {
                    sameErc20.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <div>
                                        Mumbai
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {
                                            item
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        ERC20
                                    </div>
                                </td>

                                <td>
                                    <Minter
                                        tokenAddress={item}
                                    />
                                </td>
                                <td>
                                    <button onClick={() => {
                                        setQrCodeURI(`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${item}&choe=UTF-8`)
                                    }}>
                                        Display Address QR Code
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }

                {
                    sameErc777.map((item, index) => {
                        return (
                            <tr key={`${777}:${index}`}>
                                <td>
                                    <div>
                                        Mumbai
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {
                                            item
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        ERC777
                                        <img src={`https://eips.ethereum.org/assets/eip-777/logo/png/ERC-777-logo-black-48px.png`} alt="the logo for EIP777 a bold '7' with double horizontal lines striking through to make it look like a currency symbo.l" />
                                    </div>
                                </td>
                                <td>
                                    <Minter
                                        tokenAddress={item}
                                    />
                                </td>
                                <td>
                                    <button onClick={() => {
                                        setQrCodeURI(`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${item}&choe=UTF-8`)
                                    }}>
                                        Display Address QR Code
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }


                {
                    sameErc1363.map((item, index) => {
                        return (
                            <tr key={`${1363}:${index}`}>
                                <td>
                                    <div>
                                        Mumbai
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {
                                            item
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        ERC1363
                                    </div>
                                </td>
                                <td>
                                    <Minter
                                        tokenAddress={item}
                                    />
                                </td>
                                <td>
                                    <button onClick={() => {
                                        setQrCodeURI(`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${item}&choe=UTF-8`)
                                    }}>
                                        Display Address QR Code
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }

                {
                    sameErc1155.map((item, index) => {
                        return (
                            <tr key={`${1363}:${index}`}>
                                <td>
                                    <div>
                                        Mumbai
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {
                                            item
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        ERC1155 (simple multitoken)
                                    </div>
                                </td>
                                <td>
                                    <Minter
                                        isMultiToken={true}
                                        tokenAddress={item}
                                    />
                                </td>
                                <td>
                                    <button onClick={() => {
                                        setQrCodeURI(`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${item}&choe=UTF-8`)
                                    }}>
                                        Display Address QR Code
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }



                {
                    Erc1155Type2.map((item, index) => {

                        return (
                            <tr key={`${1363}:${index}`}>
                                <td>
                                    <div>
                                        Mumbai
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {
                                            item
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        ERC1155 (advanced multitoken)
                                    </div>
                                </td>
                                <td>
                                    <Minter
                                        isMultiToken={true}
                                        tokenAddress={item}
                                    />
                                </td>
                                <td>
                                    <button onClick={() => {
                                        setQrCodeURI(`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${item}&choe=UTF-8`)
                                    }}>
                                        Display Address QR Code
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }



                <tr>
                    <td>
                        <div>
                            Mumbai
                        </div>
                    </td>
                    <td>
                        <div>

                            <a href="https://mumbai.polygonscan.com/address/0xb2ec8febe35adde790bea462a7a648eb15fc5ea7#readContract" target="_blank" rel="noreferrer">
                                0xB2EC8fEBe35ADDE790bEA462a7a648eB15Fc5Ea7
                            </a>

                        </div>
                    </td>
                    <td>
                        <div>

                            Merchant contract (ERC1363 compatible)
                        </div>
                    </td>
                    <td>
                        <button onClick={() => {
                            setQrCodeURI(`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${'0xB2EC8fEBe35ADDE790bEA462a7a648eB15Fc5Ea7'}&choe=UTF-8`)
                        }}>
                            Display Address QR Code
                        </button>
                    </td>
                </tr>

            </tbody>

        </table> */}
        {/* <h3>
            NFTs (contact william for minting)
        </h3>
        <a href="https://mumbai.polygonscan.com/address/0x519d320e8e1b59fbdd9f8459d7b9ca013e995d30" target="_blank" rel="noreferrer">
            amet (no enum)
        </a>
        <br />
        <a href="https://mumbai.polygonscan.com/address/0x1d3be216ab46b3f9fb42911d4ee8dda8821a3e61" target="_blank" rel="noreferrer">
           nulla (enum)
        </a> */}


    </div >
}

type MinterProps = {
    tokenAddress: string,
    isMultiToken?: boolean,
    // abi: any,
}

function Minter(props: MinterProps) {
    const [to, setTo] = React.useState('')
    const [tokenId, setTokenId] = React.useState('')

    const handlePressMintNormal = React.useCallback(async () => {
        const chain = supportedBlockchains.find((item) => item.name === 'mumbai')
        if (chain === undefined) {
            return
        }
        const contract = new ethers.Contract(props.tokenAddress, ['function mint(address) public'], await MetamaskHelper.connect(
            chain
        ))
        contract.mint(to)
    }, [props.tokenAddress, to])

    const handlePressMintMultiToken = React.useCallback(async () => {
        const chain = supportedBlockchains.find((item) => item.name === 'mumbai')
        if (chain === undefined) {
            return
        }
        const provider = await MetamaskHelper.connect(chain)
        const contract = new ethers.Contract(props.tokenAddress, ['function mint(address,uint256,uint256,bytes) public'], provider)
        const tx = await contract.mint(to, tokenId, '1', '0x', {
            gasLimit: (await contract.estimateGas.mint(to, tokenId, '1', '0x')).mul(2),
            gasPrice: await provider.getGasPrice()
        })
        console.log(tx.hash)

    }, [props.tokenAddress, to, tokenId])

    const handleMint = React.useCallback(async () => {
        if (props.isMultiToken) {
            handlePressMintMultiToken()
        } else {
            handlePressMintNormal()
        }
    }, [props.isMultiToken, handlePressMintMultiToken, handlePressMintNormal])



    return <div>
        <input
            placeholder="address to mint to (mumbai) "
            type="text"
            value={to}
            onChange={(e) => {
                setTo(e.target.value)
            }}
        />
        {
            props.isMultiToken && <>
                <br />
                <input
                    placeholder="token id"
                    type="text"
                    value={tokenId}
                    onChange={(e) => {
                        setTokenId(e.target.value)
                    }}
                />
            </>

        }
        <button
            onClick={
                // async () => {
                // const chain = supportedBlockchains.find((item) => item.name === 'mumbai')
                // if (chain === undefined) {
                //     return
                // }
                // const contract = new ethers.Contract(props.tokenAddress, ['function mint(address) public'], await MetamaskHelper.connect(
                //     chain
                // ))
                // contract.mint(to)
                // }
                handleMint
            }
        >
            Mint
        </button>


    </div>

}