import React from "react";
import { Outlet, Link } from "react-router-dom";
import "./Layout.css";

const Layout = () => {

  React.useEffect(() => console.log(`loaded layout`), [])

  return (
    <div className="main-navigation-bar" >
      {/* <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/initialpage">Initial Page</Link>
          </li>
          <li>
            <Link to="/home">Home</Link>
          </li>

        </ul>
      </nav> */}
      <br/>

      <Outlet />
    </div>
  )

};

export default Layout;
