/*
    a page demoing how to use theauthentication contracts
*/
import React, { useState } from 'react'
import { ethers } from 'ethers'
import { ChainData } from '../FigmaComponets/ConnectOrMakeWallet'
import BigNumber from 'bignumber.js'
import MetamaskHelper from '../MetamaskHelper'
import { checkSignature, convertSignatureForSolidity, KeyPair, KeyTracker, KeyTrackerA, Monad, signHashWithMonadAndCurry } from 'lamportwalletmanager/src'
import downloadToFile from '../functions/downloadToFile'
const factoryAddress = "0x369fBc263Dd47ead0f6C4694947972974cC5f7D4"
const factoryABI = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "instanceAddress",
                "type": "address"
            }
        ],
        "name": "DeployedInstance",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "newPrice",
                "type": "uint256"
            }
        ],
        "name": "PriceChanged",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    },
    {
        "inputs": [],
        "name": "price",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    },
    {
        "inputs": [],
        "name": "terminate",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32[]",
                "name": "firstLamportKeys",
                "type": "bytes32[]"
            }
        ],
        "name": "makeNew",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "payable",
        "type": "function",
        "payable": true
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "newPrice",
                "type": "uint256"
            }
        ],
        "name": "setPrice",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]
const authContractABI = [
    {
        "inputs": [
            {
                "internalType": "bytes32[]",
                "name": "firstLamportKeys",
                "type": "bytes32[]"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "countLive",
                "type": "uint256"
            }
        ],
        "name": "AddedPublicKeyHashes",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "countLive",
                "type": "uint256"
            }
        ],
        "name": "RemovedPublicKeyHashes",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "liveKeyCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "name": "publicKeyHashes",
        "outputs": [
            {
                "internalType": "enum PublicKeyHashUsageStatus",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "name": "signedMessages",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "publicKeyHash",
                "type": "bytes32"
            }
        ],
        "name": "isRedeemable",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32[]",
                "name": "publicKeyHashesToAdd",
                "type": "bytes32[]"
            },
            {
                "internalType": "bytes32[2][256]",
                "name": "publicKey",
                "type": "bytes32[2][256]"
            },
            {
                "internalType": "bytes[256]",
                "name": "signature",
                "type": "bytes[256]"
            }
        ],
        "name": "addPublicKeyHashes",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32[]",
                "name": "publicKeyHashesToRemove",
                "type": "bytes32[]"
            },
            {
                "internalType": "bytes32[2][256]",
                "name": "publicKey",
                "type": "bytes32[2][256]"
            },
            {
                "internalType": "bytes[256]",
                "name": "signature",
                "type": "bytes[256]"
            }
        ],
        "name": "removePublicKeyHashes",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "message",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32[2][256]",
                "name": "publicKey",
                "type": "bytes32[2][256]"
            },
            {
                "internalType": "bytes[256]",
                "name": "signature",
                "type": "bytes[256]"
            }
        ],
        "name": "signMessage",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "message",
                "type": "bytes32"
            }
        ],
        "name": "verifyMessage",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]
const networkName = "mumbai"
const chainId = `80001`

const chainData: ChainData = {
    name: networkName,
    factoryAddress: factoryAddress,
    rpc: `https://polygon-mumbai.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}}`,
    chainid: chainId,
    price: ethers.utils.parseEther("0.0001").toString(),
    explorer: "https://mumbai.polygonscan.com/",
    isTestnet: true,
    currencyTicker: "TestMATIC",
}

type SignMessageAreaProps = {
    message: string,
    setMessage: (message: string) => void,
    handleSignMessage: () => void,
    disabled: boolean
}

function SignMessageArea(props: SignMessageAreaProps) {
    // a text box to enter a message and a button to submit it to the contract
    return <div>
        <h2>Sign Message</h2>
        <input
            disabled={props.disabled}
            key="message-input-box"
            placeholder='message to sign'
            type="text"
            value={props.message}
            onChange={(e) => props.setMessage(e.target.value)} />
        <button
            disabled={props.disabled}
            onClick={props.handleSignMessage}>
            Sign Message
        </button>
    </div>
}

type VerifyMessageAreaProps = {
    message: string,
    setMessage: (message: string) => void,
    handleVerifyMessage: () => void,
    address: string,
    setAddress: (address: string) => void
}

function VerifyMessageArea(props: VerifyMessageAreaProps) {

    // two text boxes (one for the message the other for the address) and a button to submit it to the contract
    return <div>
        <h2>Verify Message</h2>
        <input
            key="message-input-box-verify"
            type="text"
            placeholder='message to verify'
            value={props.message}
            onChange={(e) => props.setMessage(e.target.value)} />
        <input
            key="address-input-box-verify"
            type="text"
            placeholder='address of signer contract'
            value={props.address}
            onChange={(e) => props.setAddress(e.target.value)} />
        <button onClick={props.handleVerifyMessage}>
            Verify Message
        </button>
    </div>
}

const packAndHashString = (input: string) => ethers.utils.keccak256(ethers.utils.solidityPack(['string'], [input]))

export default function Authentication() {
    const [keyTracker, setKeyTracker] = useState<KeyTrackerA | null>(null)
    const [authContractAddress, setAuthContractAddress] = useState<string | null>(null)
    const [message, setMessage] = useState<string>('hello world')
    const [messageToVerify, setMessageToVerify] = useState<string>('hello world')
    const [addressToVerify, setAddressToVerify] = useState<string>("0x9572821e61b93003c9cb32Bb3ebe2F6592A168ab")

    async function handleSignMessage() {
        console.log(`STUB inside handleSignMessage()`)
        if ((keyTracker === null) || (authContractAddress === null)) {
            throw new Error(`keyTracker is null`)
        }
        const signingKeys = keyTracker.getOne()
        const messageHash = packAndHashString(message)

        const signature = new Monad(messageHash)
            .bind(signHashWithMonadAndCurry(signingKeys.pri))
            .bind(checkSignature(signingKeys.pub)(messageHash))
            .bind(convertSignatureForSolidity)

        const signer: ethers.providers.JsonRpcSigner = await MetamaskHelper.connect(chainData)
        const contract = new ethers.Contract(authContractAddress, authContractABI, signer)
        const tx = await contract.signMessage(messageHash, signingKeys.pub, signature.unwrap())
        console.log(`Have tx --> ${tx.hash}`)
    }

    async function handleVerifyMessage() {
        console.log(`STUB inside handleVerifyMessage()`)
        const messageBytesHashed = packAndHashString(messageToVerify)
        const signer = await MetamaskHelper.connect(chainData) // NOTICE: a provider would be better than a signer here 
        const contract = new ethers.Contract(addressToVerify, authContractABI, signer)
        const result = await contract.verifyMessage(messageBytesHashed)
        console.log(`Have result --> ${result}`)
    }

    return <div>
        <h1>Authentication Contract Demo</h1>
        <h2>Clone Auth Wallet</h2>
        <button onClick={async () => {
            // create the wallet
            const signer: ethers.providers.JsonRpcSigner = await MetamaskHelper.connect(chainData)
            console.log(`Have signer --> ${await signer.getAddress()}`)

            const factory = new ethers.Contract(factoryAddress, factoryABI, signer)
            console.log(`Have factory --> ${factory.address}`)

            const liveKeys = new KeyTrackerA();
            const keyPairs: KeyPair[] = liveKeys.more(10)
            const publicKeyHashes = keyPairs.map(kp => KeyTracker.pkhFromPublicKey(kp.pub))

            console.log(`Have keys --> ${publicKeyHashes}`)
            const tx = await factory.makeNew(publicKeyHashes, {
                value: chainData.price,
                gasPrice: await signer.getGasPrice(),
                gasLimit: await factory.estimateGas.makeNew(publicKeyHashes, { value: chainData.price })
            })

            console.log(`Have tx --> ${tx.hash}`)
            const result = await tx.wait()

            console.log(`Have result --> ${result}, or `, result)

            const deployedAddress = result.events.find((log: any) => ['DeployedInstance'].includes(log.event)).args.instanceAddress
            console.log('deployedAddress', deployedAddress)
            setAuthContractAddress(deployedAddress)

            // save keys to local storage
            downloadToFile(JSON.stringify(liveKeys), `authWalletKeys.json`, 'application/json')

            // put keys in state 
            setKeyTracker(liveKeys)

            // display next options
            // key tracker being non null indicates that we should display the next options
        }}>
            Click to clone auth wallet
        </button>

        <SignMessageArea message={message} setMessage={setMessage} handleSignMessage={handleSignMessage} disabled={
            (keyTracker === null) || (authContractAddress === null)
        } />
        <p> Message is "{message}" </p>

        <VerifyMessageArea
            message={messageToVerify}
            setMessage={setMessageToVerify}
            handleVerifyMessage={handleVerifyMessage}
            address={addressToVerify}
            setAddress={setAddressToVerify}
        />

    </div>

}