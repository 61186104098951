import { ethers } from "ethers"


/**
 * @name getNumberOfType1ChallengesMinted
 * @description gets the number of type 1 challenges minted
 * @date Febuary 26th 2023
 * @author William Doyle
 */
export default async function getNumberOfType1ChallengesMinted(): Promise<string> {
    const polygonProvider = ethers.getDefaultProvider('https://polygon.llamarpc.com')
    const iface = new ethers.utils.Interface(['function totalSupply() view returns (uint256)'])
    const type1ChallengeContract = new ethers.Contract('0x34A86B3B9523d2d19Bbf199329983c802B3D4760', iface, polygonProvider)
    const totalSupply = await type1ChallengeContract.totalSupply()
    return totalSupply.toString()
}
