import { supportedBlockchains } from 'lamportwalletmanager/src';
import React from 'react';
import { ChainData } from '../FigmaComponets/ConnectOrMakeWallet';
import MetamaskHelper from '../MetamaskHelper';
import useLamportWalletManager from './useLamportWalletManager';
import { ethers } from 'ethers';

export type WalletMutationEvent = {
    oldPublicKeyHash: string;
    newPublicKeyHash: string;
}

export default function useLamportWalletUpdates() : WalletMutationEvent[] {
    const [events, setEvents] = React.useState<WalletMutationEvent[]>([])
    const lwm = useLamportWalletManager();
    const [chain, setChain] = React.useState<ChainData>(supportedBlockchains[0])
    const walletContract = React.useRef<ethers.Contract | null>(null);

    React.useEffect(() => {
        (async () => {
            if (lwm === null)
                return

            const _chain: ChainData | undefined = supportedBlockchains.find((chain) => chain.chainid === lwm.state.chainId)

            if (_chain === undefined)
                return

            lwm.setGasPayer(await MetamaskHelper.connect(_chain))
            setChain(_chain)

            // if wallet not null delete all listeners
            if (walletContract.current !== null && walletContract.current.listenerCount() > 0)
                walletContract.current.removeAllListeners()

            // new contract
            walletContract.current = new ethers.Contract(lwm.state.walletAddress, lwm.walletABI, await lwm.getGasPayer())

            // add listener
            walletContract.current.on("UpdatedPKH", (oldPKH: string, newPKH: string) => {
                console.log("UpdatedPKH", oldPKH, newPKH)
                setEvents((events) => [...events, {
                    oldPublicKeyHash: oldPKH,
                    newPublicKeyHash: newPKH
                }])
            })
        })()
    }, [lwm])

    return events;
}

